import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, useLocalizeMessage } from 'libs.nucleus';
import { SiteIcon } from '../../svgIcons/site.icon';
export const AvailableActivities = ({ visit, activity, setVisitActivity, setIsActivityModalOpen, }) => {
    const translate = useLocalizeMessage();
    const onHandleActivityStart = () => {
        setVisitActivity({
            visit,
            activity,
        });
        setIsActivityModalOpen();
    };
    return (_jsxs("section", { className: 'w-full bg-white border border-gray-200 mb-2.5 rounded-md flex items-center p-3', "data-testid": `site-activity-${activity.c_group_task.c_assignment._id}`, children: [_jsx(SiteIcon, { className: 'flex-1 text-gray-500' }), _jsx("span", { className: 'pl-2.5', children: activity.c_group_task.c_assignment.c_name }), _jsx("div", { className: 'ml-auto', children: _jsx(Button, { onClick: onHandleActivityStart, label: translate('Start') }) })] }, activity.c_group_task.c_assignment._id));
};
