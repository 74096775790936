import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useState } from 'react';
import { PlainDate, PlainTime } from 'temporal-luxon';
import { useLocalizeMessage, Input, DatePicker, TimePicker, Select, Button, ModalWindow, } from 'libs.nucleus';
import { useBooleanState } from 'libs.react';
import { StudyTestPlan_StepValidContexts, StudyTestPlanStepContextValues, StudyTestPlanStepType, StudyTestPlanStepUserTypes, } from '../../../types';
import { convertObjectToTestPlanContext, convertTestPlanContextToObject, findContextType, generateNextUser, GenericUtils, } from '../../../utils';
const TestPlanContextEditModal = ({ stepType, cell, onChange, onClose: closeModal, exitEditMode, getFieldOptions, addNewUser, }) => {
    const translate = useLocalizeMessage();
    const [fieldState, setFieldState] = useState({});
    const [fieldType, setFieldType] = useState();
    const [userName, setUserName] = useState('');
    const [showAddUser, openAddUser, closeAddUser] = useBooleanState(false);
    const context = useMemo(() => convertTestPlanContextToObject(cell?.value), [cell?.value]);
    const contextFields = useMemo(() => StudyTestPlan_StepValidContexts[stepType], [stepType]);
    useEffect(() => {
        setFieldState(context);
    }, [context]);
    useEffect(() => {
        if (contextFields.type === 'selector' && !fieldType) {
            const matchedType = findContextType(context, contextFields);
            setFieldType({
                value: matchedType.key,
                label: matchedType.label,
            });
        }
    }, [context, contextFields]);
    const onClose = () => {
        closeModal();
        exitEditMode();
    };
    const onSave = () => {
        const state = { ...fieldState };
        // keep only the fields that are present in the contextFields & delete the rest
        const fields = contextFields.type === 'selector'
            ? contextFields.options.find((option) => option.key === fieldType?.value)?.fields
            : contextFields.fields;
        // remove the fields that are not present in the fields
        for (const key in state) {
            if (!fields?.find((field) => field.key === key)) {
                delete state[key];
            }
        }
        const context = convertObjectToTestPlanContext(state);
        onChange({
            ...cell,
            value: context,
        });
        onClose();
    };
    const onSubmit = (event) => {
        event.preventDefault();
        onSave();
    };
    const onFieldChange = (field, value) => {
        if (value) {
            setFieldState((prev) => ({
                ...prev,
                [field.key]: value,
            }));
        }
        else {
            // check if the field exists in the state, if it does, remove it
            const newState = { ...fieldState };
            delete newState[field.key];
            setFieldState({ ...newState });
        }
    };
    const showAddUserButton = () => {
        const userType = stepType === StudyTestPlanStepType.CreateUser
            ? StudyTestPlanStepUserTypes.SITE_USER
            : StudyTestPlanStepUserTypes.PARTICIPANT;
        const users = getFieldOptions(userType === StudyTestPlanStepUserTypes.SITE_USER
            ? StudyTestPlanStepContextValues.SITE_USER
            : StudyTestPlanStepContextValues.PARTICIPANT, {}).map((option) => option.value);
        const defaultUser = generateNextUser(userType, users);
        setUserName(defaultUser);
        openAddUser();
    };
    const handleAddNewUser = (e, field) => {
        e.stopPropagation();
        e.preventDefault();
        if (!userName || userName.trim() === '') {
            return;
        }
        const user = addNewUser(stepType === StudyTestPlanStepType.CreateUser
            ? StudyTestPlanStepUserTypes.SITE_USER
            : StudyTestPlanStepUserTypes.PARTICIPANT, userName.trim());
        if (!user) {
            return;
        }
        setUserName('');
        closeAddUser();
        onFieldChange(field, user);
    };
    const getField = useCallback((field) => {
        const supportedValue = field.value;
        if (!supportedValue || !fieldState) {
            return null;
        }
        if (field.value === StudyTestPlanStepContextValues.PLAIN_TEXT) {
            return (_jsx(Input, { type: 'text', id: 'test-plan-context-field-text', label: field.label, value: fieldState[field.key] || '', onChange: (e) => {
                    onFieldChange(field, e.target.value);
                }, required: field.required }, field.label));
        }
        if (field.value === StudyTestPlanStepContextValues.DATE) {
            return (_jsx(DatePicker, { id: `test-plan-context-field-date-${field.label}`, label: field.label, value: fieldState[field.key] ? PlainDate.from(fieldState[field.key]) : undefined, onChange: (date) => {
                    if (date) {
                        onFieldChange(field, new Date(date.toString()).toISOString());
                    }
                }, required: field.required }, fieldState[field.key] || field.label));
        }
        if (field.value === StudyTestPlanStepContextValues.TIME_OF_DAY) {
            return (_jsx(TimePicker, { value: {
                    time: fieldState[field.key] ? PlainTime.from(fieldState[field.key]) : null,
                    timeZone: null,
                }, onChange: (e) => {
                    let val = [];
                    const actualValue = e.time;
                    if (actualValue) {
                        val.push(actualValue.hour.toString().padStart(2, '0'));
                        val.push(actualValue.minute.toString().padStart(2, '0'));
                        val.push(actualValue.second.toString().padStart(2, '0'));
                    }
                    else {
                        val = [];
                    }
                    onFieldChange(field, val.join(':'));
                }, required: field.required }, field.label));
        }
        if (field.value === StudyTestPlanStepContextValues.DURATION) {
            return (_jsx(Input, { type: 'text', id: 'test-plan-context-field-duration', label: field.label, value: fieldState[field.key] || '', onChange: (e) => {
                    onFieldChange(field, e.target.value);
                }, required: field.required }, field.label));
        }
        const options = getFieldOptions(supportedValue, fieldState);
        let value;
        if (field.multiple) {
            const existingValues = (fieldState[field.key] || '')?.split(',') || [];
            value = options.filter((option) => existingValues.find((value) => GenericUtils.isSameString(value, option.value)));
            return (_jsx(Select, { options: options, value: value, multiple: true, onChange: (selectedOptions) => {
                    onFieldChange(field, selectedOptions.map((option) => option.value).join(','));
                }, label: field.label, required: field.required }, field.label));
        }
        value = options.find((option) => GenericUtils.isSameString(option.value, fieldState[field.key]));
        return (_jsx(Select, { options: options, value: value, onChange: (selectedOption) => {
                onFieldChange(field, selectedOption.value);
            }, label: field.label, required: field.required }, field.label));
    }, [fieldState, getFieldOptions, onFieldChange]);
    const renderFields = useCallback(() => {
        if (contextFields.type === 'selector') {
            const { options } = contextFields;
            return (_jsxs(_Fragment, { children: [_jsx(Select, { options: options.map((option) => {
                            return {
                                value: option.key,
                                label: option.label,
                            };
                        }), value: fieldType, onChange: (selectedOption) => {
                            setFieldState({});
                            setFieldType(selectedOption);
                        }, required: true }), options
                        .find((option) => option.key === fieldType?.value)
                        ?.fields.map((field) => {
                        return getField(field);
                    })] }));
        }
        else if (contextFields.type === 'form') {
            const { fields } = contextFields;
            return fields.map((field) => {
                if (field.key === 'User' &&
                    [StudyTestPlanStepType.CreateUser, StudyTestPlanStepType.CreateParticipant].includes(stepType)) {
                    return (_jsxs(_Fragment, { children: [getField(field), showAddUser ? (_jsxs(_Fragment, { children: [_jsx(Input, { type: 'text', id: 'test-plan-context-field-new-user', label: translate('New User Name'), value: userName, onChange: (e) => setUserName(e.target.value) }, 'new-user'), _jsx(Button, { className: 'nucleus-w-input-base', label: translate('Add new user'), onClick: (e) => handleAddNewUser(e, field) }, field.key)] })) : (_jsx("div", { className: 'w-full max-w-input-base flex justify-end -mt-1', children: _jsx("a", { href: '#', className: 'text-sm text-blue-500 text-right', onClick: showAddUserButton, children: translate('Add user') }, 'add-user') }))] }));
                }
                return getField(field);
            });
        }
        return null;
    }, [
        contextFields,
        fieldType,
        fieldState,
        getField,
        stepType,
        userName,
        handleAddNewUser,
        setUserName,
        showAddUserButton,
    ]);
    const savePrimaryButton = {
        label: translate('Save'),
        onClick: onSave,
    };
    const cancelSecondaryButton = {
        label: translate('Cancel'),
        onClick: onClose,
    };
    return (_jsx(ModalWindow, { title: translate('Context Editor'), isOpen: true, closeWindow: onClose, footerPrimaryActionButton: savePrimaryButton, footerSecondaryActionButtons: [cancelSecondaryButton], width: 'sm', children: _jsx("form", { className: 'min-h-[30rem]', autoComplete: 'off', onSubmit: onSubmit, children: _jsx("div", { className: 'flex flex-col items-center gap-4', children: renderFields() }) }) }));
};
export default TestPlanContextEditModal;
