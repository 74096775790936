import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext } from 'react';
import { Instant, Now } from 'temporal-luxon';
import { DateTimeShort } from '@medable/i18n-core';
import { useLocalizeMessage, LocaleContext, Pill } from 'libs.nucleus';
import { SiteIcon } from '../../svgIcons/site.icon';
export const CompletedActivities = ({ activity }) => {
    const currentLocale = useContext(LocaleContext);
    const translate = useLocalizeMessage();
    const completionTime = new DateTimeShort(currentLocale).format(Instant.fromEpochMilliseconds(activity?.last_response?.created ? new Date(activity.last_response.created).getTime() : 0).toZonedDateTimeISO(Now.timeZone()));
    return (_jsxs("section", { className: 'w-full bg-white border border-gray-200 mb-2.5 rounded-md flex flex-wrap p-3', "data-testid": `site-activity-completed-${activity.c_group_task.c_assignment._id}`, children: [_jsxs("div", { className: 'flex items-center py-2', style: { flex: '0 0 100%' }, children: [_jsx("span", { className: 'pr-2.5', children: activity.c_group_task.c_assignment.c_name }), _jsx(Pill, { label: translate('Completed'), iconBackgroundColor: 'green', variant: 'status' })] }), _jsxs("div", { className: 'flex items-center', style: { flex: '0 0 100%' }, children: [_jsx(SiteIcon, { className: 'flex-1 text-gray-500' }), _jsx("span", { className: 'px-2.5 text-xs', "data-testid": `site-activity-completion-time-${activity.c_group_task.c_assignment._id}`, children: completionTime })] })] }, activity.c_group_task.c_assignment._id));
};
