const SpreadsheetStyles = {
    spreadsheet: 'relative overflow-visible inline-block bg-white text-black',
    activeCell: 'absolute border-2 border-blue-500 box-border',
    activeCellEdit: 'bg-white shadow-md',
    table: 'border-collapse table-fixed',
    cellBase: 'cursor-cell outline-none min-w-[6em] min-h-[1.9em] h-[1.9em] max-h-[1.9em] border border-gray-300 overflow-hidden whitespace-nowrap text-left box-border select-none max-w-[450px] text-ellipsis',
    cellReadOnly: 'color: rgba(0, 0, 0, 0.4);',
    cellError: 'bg-red-100',
    header: 'bg-gray-100 text-center font-inherit text-black min-w-[6em] min-h-[1.9em] h-[1.9em] max-h-[1.9em] border border-gray-300 overflow-hidden whitespace-nowrap text-left box-border select-none p-1',
    headerSelected: 'bg-gray-400 text-white cursor-pointer',
    commonStyles: 'p-1 box-border',
    dataViewerPreserveBreaks: 'whitespace-pre-wrap',
    dataEditor: 'w-full h-full',
    dataEditorInput: 'w-full h-full font-inherit text-inherit bg-transparent border-none outline-none m-0 p-1 box-border',
    dataViewer: 'block w-full h-full p-1 box-border',
    dataViewerFixedWidth: 'whitespace-nowrap overflow-hidden text-ellipsis',
    dataViewerBoolean: 'text-center',
    floatingRect: 'absolute pointer-events-none box-border',
    floatingRectHidden: 'hidden',
    floatingRectSelected: 'bg-blue-100/[.2] bg border-2 border-blue-500',
    floatingRectDragging: 'border-none',
    floatingRectCopied: 'border-2 border-dashed border-blue-500',
};
export default SpreadsheetStyles;
