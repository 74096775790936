export const TRANSLATION_MANAGEMENT_FLAGS = {
    instantMigrationFlag: {
        id: 'instantMigration',
        value: false,
        description: 'Enables instant migration of eCOA paper translation using AI',
        owner: 'Translation Team',
        name: 'Instant eCOA Migration (AI)',
        extra: { section: 'Translation' },
    },
    aiTranslationFlag: {
        id: 'aiTranslation',
        value: false,
        description: 'Enables instant translation using generative AI',
        owner: 'Translation Team',
        name: 'Generate Translation (AI)',
        extra: { section: 'Translation' },
    },
};
