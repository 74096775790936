import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useState } from 'react';
import { LocalizationStatus } from 'libs.localization.common';
import { useLocalizeMessage, Checkbox } from 'libs.nucleus';
export const AssetLocalePairSelection = ({ assets, locales, onSelectionChange }) => {
    const t = useLocalizeMessage();
    const [assetLocalePair, setAssetLocalePair] = useState(new Map());
    const handleSelection = useCallback((assetId, locale) => {
        if (!assetId || !locale) {
            return;
        }
        const selectedLocales = assetLocalePair.get(assetId) || [];
        const localeIndex = selectedLocales.indexOf(locale);
        if (localeIndex === -1) {
            selectedLocales.push(locale);
        }
        else {
            selectedLocales.splice(localeIndex, 1);
        }
        setAssetLocalePair(new Map(assetLocalePair.set(assetId, selectedLocales)));
    }, [assetLocalePair]);
    useEffect(() => {
        const filterAssetLocalePair = [];
        // Loop through assetLocalePair and prepare asset to locale tuple
        assetLocalePair.forEach((locales, asset_id) => {
            locales.forEach((locale) => {
                filterAssetLocalePair.push({ locale, asset_id });
            });
        });
        onSelectionChange(filterAssetLocalePair);
    }, [assetLocalePair]);
    return (_jsx("div", { className: 'w-fit', children: _jsxs("div", { className: 'flex flex-col', children: [_jsxs("div", { className: 'flex border-b border-neutral-300 font-medium text-xs text-text-secondary', children: [_jsx("div", { className: 'w-60 p-2 bg-white sticky z-10 left-0', children: t('Name') }), locales.map((locale) => {
                            return (_jsx("div", { className: 'w-24 p-2', children: locale }, locale));
                        })] }), Object.keys(assets).map((assetId) => {
                    const asset = assets[assetId];
                    const twId = asset.translationWorkbenchId;
                    const assetInState = twId && assetLocalePair.get(twId);
                    return (_jsxs("div", { className: 'flex border-b border-neutral-100 text-sm text-text-primary', children: [_jsx("div", { className: 'w-60 px-2 py-3 bg-white sticky z-10 left-0', children: assetId }), locales.map((locale) => {
                                const isDisabled = asset?.currentVersionStatus?.[locale]?.status === LocalizationStatus.NOT_AVAILABLE;
                                return (_jsx("div", { className: 'w-24 px-2 py-3 flex items-center', children: _jsx(Checkbox, { label: '', disabled: isDisabled, checked: assetInState?.includes(locale) || false, onChange: () => {
                                            handleSelection(twId, locale);
                                        } }) }, `${twId}-${locale}`));
                            })] }, assetId));
                })] }) }));
};
