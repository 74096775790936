import { jsx as _jsx } from "react/jsx-runtime";
import { LocalizationStatus } from 'libs.localization.common';
import { Pill, Tooltip, Icon } from 'libs.nucleus';
import { toSentenceCase } from '../../utils/string_utils';
const statusColorMap = {
    [LocalizationStatus.READY]: 'greenPale',
    [LocalizationStatus.IN_PROGRESS]: 'orangePale',
    [LocalizationStatus.IN_REVIEW]: 'yellowPale',
    [LocalizationStatus.NOT_STARTED]: 'redPale',
};
export const AssetVersion = ({ currentVersion, source = 'user', isLoading }) => {
    const isVersionAvailable = Boolean(currentVersion && (currentVersion.status || (currentVersion.version && currentVersion.status)));
    // Default to loading state if no version is available
    const isAssetLoading = isLoading || !isVersionAvailable;
    if (isAssetLoading) {
        if (source === 'ai') {
            return (_jsx("div", { className: 'nucleus-flex nucleus-justify-center animate-pulse', children: _jsx(Icon, { name: 'sparklesSolid', color: 'primary' }) }));
        }
        return (_jsx("div", { className: 'nucleus-flex nucleus-justify-center animate-pulse', children: _jsx(Pill, { label: '', backgroundColor: 'gray' }) }));
    }
    if (currentVersion?.status === LocalizationStatus.NOT_AVAILABLE) {
        return '-';
    }
    if (!currentVersion) {
        return null;
    }
    const { status, version } = currentVersion;
    return (_jsx("div", { className: 'nucleus-flex nucleus-justify-center', children: _jsx(Tooltip, { placement: 'top', title: `${version > 0 ? `v${version}` : 'No version'}, ${toSentenceCase(status)}`, children: _jsx(Pill, { label: version > 0 ? `v${version}` : '', backgroundColor: statusColorMap[status], "data-testid": `locale-status-icon-${status.replace(' ', '-').toLowerCase()}` }) }) }));
};
