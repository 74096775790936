import { jsx as _jsx } from "react/jsx-runtime";
import { JobStatus } from 'libs.jobs.common';
import { AssetVersion } from '../asset_version';
export const AsssetVersionCell = ({ currentVersion, job }) => {
    let assetSource = 'user'; // @TODO move to its type
    let isAssetLoading;
    if (job) {
        assetSource = 'ai';
        isAssetLoading = job.status === JobStatus.NEW || job.status === JobStatus.IN_PROGRESS;
    }
    return (_jsx("td", { className: 'px-6 py-4 whitespace-nowrap text-center border-b border-r last:border-r-0', children: _jsx(AssetVersion, { currentVersion: currentVersion, isLoading: isAssetLoading, source: assetSource }) }));
};
