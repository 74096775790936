import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Papaparse from 'papaparse';
import { useContext, useMemo, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { FormSections, Input, ModalWindow, Select, Spinner, TextArea, useLocalizeMessage, } from 'libs.nucleus';
import { ApiClientService, LibraryEndpoint, useApiClient, useToastNotification } from 'libs.react';
import { StudyConfigurationContext } from '../../contexts';
export const ImportTestPlanModal = ({ onClose, onSave, tests }) => {
    const translate = useLocalizeMessage();
    const { addNotification } = useToastNotification();
    const { studyId, currentStudy } = useContext(StudyConfigurationContext);
    const [isLoading, setIsLoading] = useState(false);
    const [studyTestForm, setStudyTestForm] = useState({
        name: '',
        description: '',
        sandbox: { label: '-select-', value: '', meta: {} },
    });
    const [testPlan, setTestPlan] = useState([]);
    const libClient = useApiClient(ApiClientService.LIBRARY);
    const saveDisabled = useMemo(() => {
        return !studyTestForm.name || !studyTestForm.sandbox.value || isLoading || !testPlan.length;
    }, [studyTestForm, isLoading, testPlan]);
    const testOptions = useMemo(() => tests.map((test) => ({
        label: translate(`${test.metadata.name}`),
        value: test.id.toString(),
        meta: {
            package: test.metadata.packages.find((pkg) => pkg.name === currentStudy.name),
        },
    })), [tests]);
    const saveTest = async () => {
        if (!saveDisabled) {
            try {
                setIsLoading(true);
                const { data: response } = await libClient.post(`${LibraryEndpoint.GET_TEST_PLANS}`, {
                    name: studyTestForm.name,
                    key: uuidv4(),
                    data: {
                        description: studyTestForm.description,
                        csv: testPlan,
                    },
                    metadata: {
                        name: studyTestForm.name,
                        sandboxId: Number(studyTestForm.sandbox.value),
                        sandboxName: studyTestForm.sandbox.label,
                        sandboxResourceId: studyTestForm.sandbox.meta?.package?.resourceId,
                    },
                    studyId,
                });
                onSave(response.data);
            }
            catch (error) {
                console.error('Error creating test plan', error);
                onError();
            }
        }
    };
    const onError = (message) => {
        const subtitle = message
            ? translate('The test plan could not be created because of "{message}"', { message })
            : translate('The test plan could not be created. Please try again.');
        addNotification({ title: translate('Error creating test plan'), subtitle, type: 'error' });
        setIsLoading(false);
    };
    const savePrimaryButton = {
        label: translate('Generate'),
        onClick: saveTest,
        disabled: saveDisabled,
    };
    const cancelSecondaryButton = {
        label: translate('Cancel'),
        onClick: onClose,
    };
    const handleEnterPress = (event) => {
        if (event.key === 'Enter' && !event.shiftKey) {
            saveTest();
        }
    };
    const onSubmit = (event) => {
        event.preventDefault();
        saveTest();
    };
    const handleInputChange = (event) => {
        const { id, value } = event.target;
        setStudyTestForm((oldForm) => ({ ...oldForm, [id]: value }));
    };
    const handleTestChange = (selectedTest) => {
        setStudyTestForm((oldForm) => ({
            ...oldForm,
            sandbox: {
                ...selectedTest,
                meta: selectedTest.meta || { package: undefined },
            },
        }));
    };
    const onFileChange = (event) => {
        const file = event.target.files?.[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const csv = e.target?.result;
                const { data } = Papaparse.parse(csv, { header: true });
                setTestPlan(data);
            };
            reader.readAsText(file);
        }
    };
    const formSections = [
        {
            title: translate('Test plan details'),
            content: (_jsxs("div", { className: 'flex flex-col gap-6', children: [_jsx(Input, { id: 'name', label: translate('Name'), maxLength: 50, onChange: handleInputChange, required: true, value: studyTestForm.name, width: 'lg', autoComplete: 'off' }), _jsx(TextArea, { id: 'description', label: translate('Description'), onChange: handleInputChange, value: studyTestForm.description, width: 'lg' })] })),
        },
        {
            title: translate('Sandbox'),
            content: (_jsx("div", { className: 'flex flex-col gap-6 w-[27.5rem]', children: _jsx(Select, { dataTestId: 'select-sandbox', label: translate('Select sandbox'), description: translate('Select the sandbox to generate a test plan'), onChange: handleTestChange, options: testOptions, required: true, value: studyTestForm.sandbox, width: 'lg' }) })),
        },
        {
            title: translate('CSV Import'),
            content: (_jsxs("div", { className: 'flex flex-col gap-6 w-[27.5rem]', children: [_jsx("p", { className: 'text-sm text-gray-600', children: translate('Please select the CSV file that contains the test plan you want to import. The CSV file must have the following columns: Step Type, User, Context. Optional fields are Execution Mode, User Input') }), _jsx("input", { type: 'file', id: 'csv', accept: '.csv', onChange: onFileChange })] })),
        },
    ];
    return (_jsxs(ModalWindow, { title: translate('Import CSV'), isOpen: true, closeWindow: onClose, footerPrimaryActionButton: savePrimaryButton, footerSecondaryActionButtons: [cancelSecondaryButton], width: 'full', children: [isLoading && _jsx(Spinner, { wrapper: 'full' }), _jsx("form", { className: 'min-h-[30rem]', autoComplete: 'off', onSubmit: onSubmit, onKeyUp: handleEnterPress, children: _jsx(FormSections, { sections: formSections }) })] }));
};
