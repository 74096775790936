import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext, useMemo, useState } from 'react';
import { useLocalizeMessage, ModalWindow, Text } from 'libs.nucleus';
import { StudyConfigurationContext } from '../../../../contexts';
import { useActivityUtils } from '../../../../hooks';
import { ActivityDependencies, EndingConditionType, StartingConditionType, useActivityDependencyUtils, ActivityDependencyUtils, } from '../activity_dependencies';
export const ActivityInstanceEditModal = ({ activityToEdit, visit, onCancel, onSave, }) => {
    const translate = useLocalizeMessage();
    const { getActivityIcon } = useActivityUtils();
    const activityDependencyUtils = useActivityDependencyUtils({
        currentActivity: activityToEdit,
        currentVisit: visit,
    });
    const [isPristine, setIsPristine] = useState(true);
    const { startingConditions, endingConditions } = activityDependencyUtils;
    const { currentStudy, currentTimelineId } = useContext(StudyConfigurationContext);
    const isSaveDisabled = useMemo(() => {
        if (isPristine) {
            return true;
        }
        return (startingConditions.some((condition) => (condition.type === StartingConditionType.ACTIVITY_RESPONSE &&
            Object.values(condition.activityResponse).some((v) => !v.value)) ||
            (condition.type === StartingConditionType.DATE_RESPONSE &&
                (condition.activityResponse.activity.value === '' || condition.activityResponse.screen.value === '')) ||
            (condition.type === StartingConditionType.AFTER_ACTIVITY_COMPLETION && !condition.activityCompletion.value)) ||
            endingConditions.some((condition) => (condition.type === EndingConditionType.AFTER_ACTIVITY_COMPLETION && !condition.activityCompletion.value) ||
                (condition.type === EndingConditionType.AFTER_DURATION && !condition.duration) ||
                (condition.type === EndingConditionType.ACTIVITY_RESPONSE &&
                    Object.values(condition.activityResponse).some((v) => !v.value)) ||
                (condition.type === EndingConditionType.DATE_RESPONSE &&
                    (condition.activityResponse.activity.value === '' || condition.activityResponse.screen.value === ''))));
    }, [startingConditions, endingConditions, isPristine]);
    const convertConditions = () => {
        const activitySequence = visit.getActivitySequenceByActivityId(activityToEdit.id);
        const baseCondition = {
            activityId: activityToEdit.id,
            activityInstanceId: activitySequence.instanceId,
            encounterId: visit.id,
            scheduleTimelineId: currentTimelineId,
        };
        const endConditions = ActivityDependencyUtils.convertEndConditions({
            activitySequence,
            baseCondition,
            conditions: endingConditions,
        });
        const startConditions = ActivityDependencyUtils.convertStartConditions({
            baseCondition,
            conditions: startingConditions,
        });
        return { endConditions, startConditions };
    };
    const handleSave = () => {
        if (!isSaveDisabled) {
            const conditions = convertConditions();
            onSave(conditions);
        }
    };
    const saveButton = {
        label: translate('Save'),
        onClick: handleSave,
        disabled: isSaveDisabled,
    };
    const cancelButton = {
        label: translate('Cancel'),
        onClick: onCancel,
    };
    const onSubmit = (event) => {
        event.preventDefault();
        handleSave();
    };
    const handleEnterPress = (event) => {
        if (event.key === 'Enter') {
            handleSave();
        }
    };
    const modalSubtitle = (_jsxs("div", { className: 'flex items-center gap-2', children: [getActivityIcon(activityToEdit.responderRole), _jsx(Text, { size: 'sm', className: 'text-secondary', as: 'span', children: translate('{timeline} - {visit} - {activity}', {
                    activity: activityToEdit.name,
                    timeline: currentStudy.timelineInfo[currentTimelineId].name,
                    visit: visit.name,
                }) })] }));
    return (_jsx(ModalWindow, { title: translate('Edit visit activity'), subtitle: modalSubtitle, isOpen: true, width: 'full', footerPrimaryActionButton: saveButton, footerSecondaryActionButtons: [cancelButton], closeWindow: onCancel, children: _jsx("form", { className: 'flex flex-col gap-4', onSubmit: onSubmit, autoComplete: 'off', onKeyUp: handleEnterPress, children: _jsx(ActivityDependencies, { onPristineChange: setIsPristine, activityDependencyUtils: activityDependencyUtils, activity: activityToEdit, visit: visit }) }) }));
};
