export const STUDY_MANAGEMENT_FLAGS = {
    nextRelease: {
        id: 'nextRelease',
        value: false,
        description: 'Enables features for the next release (study televisit, among others)',
        owner: 'Enterprise experience team',
        name: 'Next Release (currently 3.4.0)',
        extra: { section: 'Studies' },
    },
    cloneStudies: {
        id: 'cloneStudies',
        value: false,
        description: 'Enables the ability to clone a study',
        owner: 'Enterprise experience team',
        name: 'Clone studies',
        extra: { section: 'Studies' },
    },
    review: {
        id: 'review',
        value: false,
        description: 'Enables the review tab under Build section for a study',
        owner: 'Enterprise experience team',
        name: 'Review',
        extra: { section: 'Studies' },
    },
    chat: {
        id: 'chat',
        value: false,
        description: 'Enables AI chat',
        owner: 'Enterprise experience team',
        name: 'Medable chat',
        extra: { section: 'Studies' },
    },
    restoreVersion: {
        id: 'restoreVersion',
        value: false,
        description: 'Enables Restoring of study version',
        owner: 'Enterprise experience team',
        name: 'Restore Version',
        extra: { section: 'Studies' },
    },
    experimentalPackages: {
        id: 'experimentalPackages',
        value: false,
        description: `When enabled, study build will use non-stable 'dev-latest' template`,
        owner: 'Enterprise experience team',
        name: 'Use latest template',
        extra: { section: 'Studies' },
    },
    testPlans: {
        id: 'testPlans',
        value: false,
        description: 'Enables the test plans tab under Tests section for a study',
        owner: 'Enterprise experience team',
        name: 'Test Plans',
        extra: { section: 'Studies' },
    },
    siteManagement: {
        id: 'siteManagement',
        value: false,
        description: 'Enables the Site Management experience for a study',
        owner: 'Site experience team',
        name: 'Site management',
        extra: { section: 'Studies' },
    },
};
