import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { StudyLocalizationStatus } from '@axon/rosetta-sdk';
import { FirebaseAppContext } from 'libs.firebase_react';
import { useStudyL10nMap } from 'libs.localization.react';
import { Button, useLocalizeMessage } from 'libs.nucleus';
import { useBooleanState } from 'libs.react';
import { StudyTranslationStatusModal } from './study_translation_status_modal.component';
import { TranslationWorkbenchContext } from '../../contexts';
import { useStudyTranslationTransition } from '../../hooks';
import { LockToggleOffIcon } from '../icons/lock-toggle-off.icon';
import { LockToggleOnIcon } from '../icons/lock-toggle-on.icon';
export const StudyTranslationStatusWorkflow = ({ study, updateStudy }) => {
    const t = useLocalizeMessage();
    const { studyId } = useParams();
    const [isModalOpen, openModal, closeModal] = useBooleanState(false);
    const [isStudyTransistionCompleted, setIsStudyTransistionCompleted] = useState(false);
    const { localizationStatus: studyLocalizationStatus = StudyLocalizationStatus.NOT_STARTED } = study || {};
    const { resourcesAssets, studyAssets, notificationsAssets, locales, libraryAssets } = useContext(TranslationWorkbenchContext);
    const { logEvent } = useContext(FirebaseAppContext);
    const { isPending: isSettingStudyL10nMap, setStudyL10nMap } = useStudyL10nMap();
    const assets = { ...resourcesAssets, ...studyAssets, ...notificationsAssets };
    const assetData = Object.values(assets)
        .filter((asset) => Boolean(asset.asset))
        .map((asset) => asset.asset);
    const allAssets = { ...resourcesAssets, ...studyAssets, ...notificationsAssets, ...libraryAssets };
    const allIdString = Object.keys(allAssets).join(',');
    const isLocked = studyLocalizationStatus !== StudyLocalizationStatus.READY;
    const { mutate: transitionStudyTranslation, isPending } = useStudyTranslationTransition(studyId);
    const updateLocalizationStatus = useCallback((status, l10nMapResourceId) => {
        logEvent('translations_study_localization_status_change', {
            studyId,
            status,
            previousStatus: studyLocalizationStatus,
        });
        study.localizationStatus = status;
        if (l10nMapResourceId) {
            study.l10nMapResourceId = l10nMapResourceId;
        }
        closeModal();
        updateStudy(study);
    }, []);
    const handleStatusChange = useCallback(async () => {
        if ((studyLocalizationStatus === StudyLocalizationStatus.DRAFT ||
            studyLocalizationStatus === StudyLocalizationStatus.NOT_STARTED) &&
            assets) {
            transitionStudyTranslation({ assets: assetData }, {
                onSuccess: () => {
                    setIsStudyTransistionCompleted(true);
                },
            });
        }
        if (studyLocalizationStatus === StudyLocalizationStatus.READY) {
            updateLocalizationStatus(StudyLocalizationStatus.DRAFT);
        }
    }, [assetData, assets]);
    useEffect(() => {
        if (isStudyTransistionCompleted) {
            const assetLocaleVersionSet = [];
            locales.forEach((locale) => {
                Object.values(allAssets).forEach((asset) => {
                    if (!asset.translationWorkbenchId ||
                        !asset?.currentVersionStatus?.[locale] ||
                        Number(asset?.latestVersionStatus?.[locale]?.version || 0) === 0) {
                        return;
                    }
                    assetLocaleVersionSet.push({
                        id: asset.translationWorkbenchId,
                        locale,
                        version: Number(asset?.currentVersionStatus?.[locale].version),
                    });
                });
            });
            setStudyL10nMap({
                studyId: Number(studyId),
                assetLocaleVersionSet,
            }, {
                onSuccess: (response) => {
                    if (response?.data?.id) {
                        updateLocalizationStatus(StudyLocalizationStatus.READY, response.data?.id);
                    }
                    setIsStudyTransistionCompleted(false);
                },
            });
        }
    }, [isStudyTransistionCompleted, allIdString]);
    return (_jsxs(_Fragment, { children: [_jsx("div", { className: 'flex gap-4 items-center', children: _jsx(Button, { label: t('Change status'), onClick: openModal, variant: 'secondary', content: isLocked ? (_jsxs(_Fragment, { children: [_jsx(LockToggleOffIcon, {}), _jsx("span", { className: 'ml-2 nucleus-text-sm', children: t('Lock build for translation') })] })) : (_jsxs(_Fragment, { children: [_jsx(LockToggleOnIcon, {}), _jsx("span", { className: 'ml-2 nucleus-text-sm', children: t('Unlock build to modify') })] })) }) }), isModalOpen && (_jsx(StudyTranslationStatusModal, { onModalClose: closeModal, onHandleStatusChange: handleStatusChange, isLoading: isPending || isSettingStudyL10nMap, currentStatus: studyLocalizationStatus }))] }));
};
