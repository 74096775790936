import { jsx as _jsx } from "react/jsx-runtime";
import { useQuery } from '@tanstack/react-query';
import { createContext, useContext, useMemo } from 'react';
import { useMatch } from 'react-router-dom';
import { getApiInstance } from '../../helpers/api.helpers';
import { useCurrentEnvironment } from '../../hooks/use_current_environment';
import { AxonAccountContext } from '../axon_account';
import { SiteContext } from '../site';
export const OrgContext = createContext({
    isLoading: true,
    org: null,
});
export const OrgProvider = ({ children }) => {
    const match = useMatch('/site/study/:studyId/*');
    const { account } = useContext(AxonAccountContext);
    const { site } = useContext(SiteContext);
    const { environment } = useCurrentEnvironment(match?.params?.studyId);
    const getOrg = async () => {
        if (!environment || !account || !site) {
            return;
        }
        const url = `/v2/account/${account._id}/c_sites/${site?._id}/c_study`;
        const { data: response } = await getApiInstance(environment).get(url, {
            params: {
                expand: ['c_study', 'dcr__default_dcr_configurations.dcr__dcr_configuration', 'c_locks'],
                include: ['c_subject_status_list'],
            },
        });
        return response || null;
    };
    const { data: org, isLoading } = useQuery({
        queryKey: ['getOrg'],
        queryFn: getOrg,
        enabled: !!environment && !!account && !!site,
    });
    const value = useMemo(() => ({
        isLoading,
        org,
    }), [org]);
    return _jsx(OrgContext.Provider, { value: value, children: children });
};
