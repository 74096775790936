import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useQuery } from '@tanstack/react-query';
import { useContext } from 'react';
import { useMatch } from 'react-router-dom';
import { Button, useLocalizeMessage, Icon, InlineNotification, Spinner } from 'libs.nucleus';
import { AvailableActivities } from './activities_list_components/available_activities.component';
import { CompletedActivities } from './activities_list_components/completed_activities.component';
import { DisabledActivities } from './activities_list_components/disabled_activities.component';
import { SiteContext } from '../../contexts/site';
import { getApiInstance } from '../../helpers/api.helpers';
import { isOutsideVisitWindowRange } from '../../helpers/visit.helpers';
import { useCurrentEnvironment } from '../../hooks/use_current_environment';
import { UserIcon } from '../svgIcons/user.icon';
import { isActivityAvailableForCompletion } from './helpers/activity_scheduling.helpers';
export const SiteTasksList = ({ isVisitConfirmationComplete, visitConfirmActivity, tasks, visit, setVisitActivity, setIsActivityModalOpen, }) => {
    const translate = useLocalizeMessage();
    const disableActivities = visitConfirmActivity && !isVisitConfirmationComplete;
    if (tasks.length === 0) {
        return (_jsx("section", { className: 'flex items-center p-4', children: _jsx("span", { className: 'px-2', children: translate('No site tasks available') }) }));
    }
    return tasks
        .filter((task) => !task.c_group_task.c_assignment?.c_visit_confirmation)
        .map((task) => {
        const isAvailable = isActivityAvailableForCompletion(task, task?.last_response);
        if (disableActivities) {
            return _jsx(DisabledActivities, { activity: task }, task.c_group_task.c_assignment._id);
        }
        if (!isAvailable && task.completed_count > 0) {
            return _jsx(CompletedActivities, { activity: task }, task.c_group_task.c_assignment._id);
        }
        if (isAvailable && task.completed_count > 0) {
            return (_jsxs("div", { children: [_jsx(AvailableActivities, { activity: task, setVisitActivity: setVisitActivity, setIsActivityModalOpen: setIsActivityModalOpen, visit: visit }), _jsx(CompletedActivities, { activity: task })] }, `${task.c_group_task.c_assignment._id}-${task.last_response?._id}`));
        }
        return (_jsx(AvailableActivities, { activity: task, setVisitActivity: setVisitActivity, setIsActivityModalOpen: setIsActivityModalOpen, visit: visit }, task.c_group_task.c_assignment._id));
    });
};
export const ParticipantTasksList = ({ tasks }) => {
    const translate = useLocalizeMessage();
    if (tasks.length === 0) {
        return (_jsx("section", { className: 'flex items-center p-4', children: _jsx("span", { className: 'px-2', children: translate('No participant tasks available') }) }));
    }
    return tasks.map((task) => {
        return (_jsxs("section", { className: 'flex items-center p-4 bg-neutral-200', "data-testid": `participant-task-${task.c_task._id}`, children: [_jsx(UserIcon, { className: 'flex-1 nucleus-text-blue-500' }), _jsx("span", { className: 'px-2', children: task.c_task.c_name }), _jsxs("span", { className: 'ml-auto', children: [' ', _jsx(Icon, { color: 'black', name: 'lock', size: 'base' })] })] }, task.c_task._id));
    });
};
export const ActivitiesList = ({ visit, setVisitActivity, setIsActivityModalOpen, isOnboarding = false, onboardingSchedule, }) => {
    const { environment } = useCurrentEnvironment();
    const { site } = useContext(SiteContext);
    const translate = useLocalizeMessage();
    const match = useMatch('/site/study/:studyId/participants/:participantId/*');
    const participantId = match?.params.participantId;
    const visitId = visit.c_visit;
    const getActivities = async () => {
        if (!environment || !visit.c_group) {
            return [{ siteTasks: [], participantTasks: [] }];
        }
        try {
            const responseSiteTasks = await getApiInstance(environment).get(`/v2/routes/c_sites/${site?._id}/c_public_users/${participantId}/c_groups/${visit.c_group}/c_task_assignments`);
            // TODO: Uncomment this block after the participant event permissions are fixed or find a different way to get the participant events
            // const responseParticipantTasks = await getApiInstance(environment).get(
            //   `/v2/routes/c_public_users/${participantId}/c_events`,
            //   {
            //     params: {
            //       paths: [
            //         'c_start',
            //         'c_end',
            //         'c_task.c_name',
            //         'c_task.c_type',
            //         'c_completed',
            //         'type',
            //         'c_missed',
            //         'c_task_assignment.c_schedule_rules',
            //         'c_auto_start',
            //         'c_canceled',
            //         'c_title',
            //         'c_timezone',
            //         'c_task_assignment.c_order',
            //         'c_order',
            //         'c_visit_id',
            //         'c_visit_name',
            //         'c_enforce_order',
            //         'c_task_assignment.c_visit',
            //         'c_modality',
            //       ],
            //     },
            //   }
            // );
            return [
                {
                    siteTasks: responseSiteTasks?.data.data,
                    participantTasks: [],
                },
            ];
        }
        catch (error) {
            console.error('Error getting participant activities', error);
            return [{ siteTasks: [], participantTasks: [] }];
        }
    };
    const { data: participantActivities, isLoading } = useQuery({
        queryKey: ['participantActivities', participantId, visitId],
        queryFn: getActivities,
        enabled: !isOnboarding,
    });
    if (isLoading) {
        return _jsx(Spinner, { wrapper: 'full' });
    }
    const visitConfirmActivity = participantActivities?.[0]?.siteTasks.find((task) => task.c_group_task.c_assignment.c_visit_confirmation);
    const isVisitConfirmationComplete = visitConfirmActivity && visitConfirmActivity?.last_response !== null && visitConfirmActivity?.completed_count > 0;
    return (_jsxs("div", { className: 'bg-neutral-100 p-4 h-full', children: [!isOnboarding && visit.c_group && isOutsideVisitWindowRange(new Date(), visit) && (_jsx("section", { className: 'flex items-center pb-4', children: _jsx(InlineNotification, { subtitle: translate('This visit is outside the visit window'), title: '', type: 'warning' }) })), visitConfirmActivity && !isVisitConfirmationComplete && (_jsxs("div", { className: 'flex py-4', children: [_jsx("div", { className: 'content-center', children: _jsx("p", { children: translate('Activities will be enabled once the visit has started') }) }), _jsx("div", { className: 'ml-auto', children: _jsx(Button, { onClick: () => { }, label: translate('Start visit') }) })] })), _jsx(SiteTasksList, { isVisitConfirmationComplete: isVisitConfirmationComplete, visitConfirmActivity: visitConfirmActivity, tasks: isOnboarding ? onboardingSchedule : participantActivities?.[0]?.siteTasks || [], visit: visit, setVisitActivity: setVisitActivity, setIsActivityModalOpen: setIsActivityModalOpen })] }));
};
