import { jsx as _jsx } from "react/jsx-runtime";
import classnames from 'classnames';
import * as React from 'react';
import SpreadsheetStyles from './spreadsheet_styles';
import { useSpreadsheetDispatch, useSpreadsheetSelector } from '../../contexts/spreadsheet';
import { getCellDimensions } from '../../utils/spreadsheet';
import * as Matrix from '../../utils/spreadsheet/spreadsheet_matrix.utils';
const ActiveCell = (props) => {
    const rootRef = React.useRef(null);
    const { setCellData, edit, commit, showContextMenu, view } = useSpreadsheetDispatch();
    const active = useSpreadsheetSelector((state) => state.active);
    const mode = useSpreadsheetSelector((state) => state.mode);
    const cell = useSpreadsheetSelector((state) => (state.active ? Matrix.get(state.active, state.data) : undefined));
    const dimensions = useSpreadsheetSelector((state) => active ? getCellDimensions(active, state.rowDimensions, state.columnDimensions) : undefined);
    const hidden = React.useMemo(() => !active || !dimensions, [active, dimensions]);
    const initialCellRef = React.useRef(undefined);
    const prevActiveRef = React.useRef(null);
    const prevCellRef = React.useRef(undefined);
    const handleChange = React.useCallback((cell) => {
        if (!active) {
            return;
        }
        setCellData(active, cell);
    }, [setCellData, active]);
    React.useEffect(() => {
        const root = rootRef.current;
        if (!hidden && root) {
            root.focus();
        }
    }, [rootRef, hidden]);
    React.useEffect(() => {
        const prevActive = prevActiveRef.current;
        const prevCell = prevCellRef.current;
        prevActiveRef.current = active;
        prevCellRef.current = cell;
        if (!prevActive || !prevCell) {
            return;
        }
        // Commit
        const coordsChanged = active?.row !== prevActive.row || active?.column !== prevActive.column;
        const exitedEditMode = mode !== 'edit';
        if (coordsChanged || exitedEditMode) {
            const initialCell = initialCellRef.current;
            if (prevCell !== initialCell) {
                commit([
                    {
                        prevCell: initialCell || null,
                        nextCell: prevCell,
                    },
                ]);
            }
            else if (!coordsChanged && cell !== prevCell) {
                commit([
                    {
                        prevCell,
                        nextCell: cell || null,
                    },
                ]);
            }
            initialCellRef.current = cell;
        }
    });
    const DataEditor = (cell && cell.DataEditor) || props.DataEditor;
    const readOnly = cell && cell.readOnly;
    return hidden ? null : (_jsx("div", { ref: rootRef, className: classnames(SpreadsheetStyles.activeCell, {
            [SpreadsheetStyles.activeCellEdit]: mode === 'edit',
        }), style: dimensions, onClick: mode === 'view' && !readOnly ? edit : undefined, tabIndex: 0, onContextMenu: (e) => {
            e.preventDefault();
            if (mode === 'view') {
                showContextMenu(e);
            }
        }, children: mode === 'edit' && active ? (_jsx(DataEditor, { row: active.row, column: active.column, cell: cell, onChange: handleChange, exitEditMode: view })) : null }));
};
export default ActiveCell;
