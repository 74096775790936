import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { AxiosError } from 'axios';
import { isEmpty, isEqual, trim, mapValues, omit, omitBy } from 'lodash';
import { useState } from 'react';
import { FormSections, useLocalizeMessage, Input, ModalWindow, Select, Spinner } from 'libs.nucleus';
import { useToastNotification } from 'libs.react';
import { validateFormFieldOnBlur, validateFormFieldOnChange, timezoneOptions, countryOptions, localesOptions, stateOptions, } from './helpers';
import { useMutateWorkspaceSiteById } from '../../hooks/useWorkspaceSites.hook';
export const WorkspaceSiteModal = ({ isOpen, onClose, workspaceSite }) => {
    const formInitValue = {
        name: workspaceSite?.name || '',
        number: workspaceSite?.number || '',
        timezone: workspaceSite?.timezone || '',
        siteLocales: workspaceSite?.siteLocales || [],
        email: workspaceSite?.email || '',
        phone: workspaceSite?.address.phone[0]?.number || '',
        countryCode: workspaceSite?.address.countryCode || '',
        addressLine: workspaceSite?.address.addressLine || '',
        city: workspaceSite?.address.city || '',
        region: workspaceSite?.address.region || '',
        postalCode: workspaceSite?.address.postalCode || '',
    };
    const translate = useLocalizeMessage();
    const { addNotification } = useToastNotification();
    const [form, setForm] = useState(formInitValue);
    const [errors, setErrors] = useState({});
    const { isPending, mutateAsync } = useMutateWorkspaceSiteById(workspaceSite?.id); // todo: add error handling
    const isEditMode = !isEmpty(workspaceSite);
    const isSubmitButtonDisabled = () => {
        // check if mutation/saving is pending
        if (isPending) {
            return true;
        }
        // check if there are any validation errors
        if (!isEmpty(errors)) {
            return true;
        }
        // check if initial values are equal to form values
        if (isEditMode) {
            const preparedFromValue = mapValues(form, (value) => (typeof value === 'string' ? trim(value) : value));
            return isEqual(preparedFromValue, formInitValue);
        }
        return false;
    };
    const getSelectValue = (options, value) => {
        return isEmpty(value) ? { label: '', value: '' } : options.find((option) => option.value === value);
    };
    const getMultiselectValue = (options, value) => {
        return isEmpty(value) ? [] : options.filter((option) => value.includes(option.value));
    };
    const validateFormOnSubmit = () => {
        const errors = {};
        Object.keys(form).forEach((key) => {
            const errorOnChange = validateFormFieldOnChange(key, form[key]);
            const errorOnBlur = validateFormFieldOnBlur(key, form[key]);
            const error = errorOnBlur || errorOnChange;
            if (error) {
                errors[key] = translate(error);
            }
        });
        return errors;
    };
    const handleInputFieldChange = (event) => {
        const { name, value } = event.target;
        const error = validateFormFieldOnChange(name, value);
        setForm((prevState) => ({ ...prevState, [name]: value }));
        setErrors((prevState) => (error ? { ...prevState, [name]: translate(error) } : omit(prevState, name)));
    };
    const handleInputFieldBlur = (event) => {
        const { name, value } = event.target;
        const trimmedValues = value.trim();
        const error = validateFormFieldOnBlur(name, trimmedValues);
        if (error !== errors[name]) {
            setErrors((prevState) => (error ? { ...prevState, [name]: translate(error) } : omit(prevState, name)));
        }
        if (trimmedValues !== form[name]) {
            setForm((prevState) => ({ ...prevState, [name]: trimmedValues }));
        }
    };
    const handleSelectFieldChange = (name, value) => {
        const error = validateFormFieldOnChange(name, value.value);
        setForm((prevState) => ({ ...prevState, [name]: value.value }));
        setErrors((prevState) => (error ? { ...prevState, [name]: translate(error) } : omit(prevState, name)));
    };
    const handleMultiselectFieldChange = (name, value) => {
        const preparedValue = value.map((option) => option.value);
        const error = validateFormFieldOnChange(name, preparedValue);
        setForm((prevState) => ({ ...prevState, [name]: preparedValue }));
        setErrors((prevState) => (error ? { ...prevState, [name]: translate(error) } : omit(prevState, name)));
    };
    const handleClose = () => {
        setForm(formInitValue);
        setErrors({});
        onClose();
    };
    const handleSubmitForm = async () => {
        const errors = validateFormOnSubmit();
        if (!isEmpty(errors)) {
            setErrors(errors);
            return;
        }
        try {
            const preparedForm = omitBy(form, isEmpty);
            const payload = {
                name: preparedForm.name,
                number: preparedForm.number,
                timezone: preparedForm.timezone,
                siteLocales: preparedForm.siteLocales,
                email: preparedForm.email,
                address: {
                    countryCode: preparedForm.countryCode,
                    addressLine: preparedForm.addressLine,
                    city: preparedForm.city,
                    region: preparedForm.region,
                    postalCode: preparedForm.postalCode,
                    phone: preparedForm.phone
                        ? [
                            {
                                type: '24-hour',
                                number: preparedForm.phone,
                            },
                        ]
                        : undefined,
                },
            };
            await mutateAsync(payload);
            addNotification({
                title: isEditMode ? translate('Edit workspace site') : translate('Create workspace site'),
                subtitle: isEditMode ? translate('Site record has been updated.') : translate('Site record has been created.'),
                type: 'success',
            });
            if (isEditMode) {
                setErrors({});
                onClose();
            }
            else {
                setForm(formInitValue);
                setErrors({});
                onClose();
            }
        }
        catch (error) {
            console.error(error);
            // todo: improve validations or axon error handle to show more specific error messages on form without closing form itself
            // todo: FYI: for now for some reason error is not instance of AxiosError / axios interceptors can not hadle be error correctly
            if (error instanceof AxiosError) {
                const title = '';
                const subtitle = '';
                const defaultErrorTitle = isEditMode
                    ? translate('Edit workspace site error')
                    : translate('Create workspace site error');
                const defaultErrorMessage = isEditMode
                    ? translate('Site record was not updated')
                    : translate('Site record was not created');
                addNotification({
                    title: title || defaultErrorTitle,
                    subtitle: subtitle || defaultErrorMessage,
                    type: 'error',
                });
            }
            else {
                addNotification({
                    title: isEditMode ? translate('Edit workspace site') : translate('Create workspace site'),
                    subtitle: isEditMode ? translate('Site record was not updated') : translate('Site record was not created'),
                    type: 'error',
                });
            }
            handleClose();
        }
    };
    const getDetailsSectionContent = () => {
        return (_jsxs("div", { className: 'flex flex-col gap-4', children: [_jsx(Input, { id: 'name', dataTestId: 'name', name: 'name', label: translate('Name'), width: 'lg', required: true, hasError: !!errors.name, errorMessage: errors.name, value: form.name, onChange: handleInputFieldChange, onBlur: handleInputFieldBlur }), _jsx(Input, { id: 'number', dataTestId: 'number', name: 'number', label: translate('Site library ID'), width: 'lg', hasError: !!errors.number, errorMessage: errors.number, value: form.number, onChange: handleInputFieldChange, onBlur: handleInputFieldBlur }), _jsx(Select, { dataTestId: 'timezone', label: translate('Time zone'), options: timezoneOptions, width: 'lg', hasError: !!errors.timezone, errorMessage: errors.timezone, value: getSelectValue(timezoneOptions, form.timezone), onChange: (value) => handleSelectFieldChange('timezone', value) }), _jsx(Select, { multiple: true, dataTestId: 'site-locale', label: translate('Locale'), options: localesOptions, width: 'lg', hasError: !!errors.siteLocales, errorMessage: errors.siteLocales, value: getMultiselectValue(localesOptions, form.siteLocales), onChange: (value) => handleMultiselectFieldChange('siteLocales', value) })] }));
    };
    const getContactInformationSectionContent = () => {
        return (_jsxs("div", { className: 'flex flex-col gap-4', children: [_jsx(Input, { id: 'email', dataTestId: 'email', name: 'email', label: translate('Email'), width: 'lg', hasError: !!errors.email, errorMessage: errors.email, value: form.email, onChange: handleInputFieldChange, onBlur: handleInputFieldBlur }), _jsx(Input, { required: true, id: 'phone', dataTestId: 'phone', name: 'phone', label: translate('Phone'), width: 'lg', hasError: !!errors.phone, errorMessage: errors.phone, value: form.phone, onChange: handleInputFieldChange, onBlur: handleInputFieldBlur }), _jsx(Select, { required: true, dataTestId: 'country-code', label: translate('Country'), width: 'lg', hasError: !!errors.countryCode, errorMessage: errors.countryCode, options: countryOptions, value: getSelectValue(countryOptions, form.countryCode), onChange: (value) => handleSelectFieldChange('countryCode', value) }), _jsx(Input, { required: true, id: 'addressLine', dataTestId: 'address-line', name: 'addressLine', label: translate('Address line'), width: 'lg', hasError: !!errors.addressLine, errorMessage: errors.addressLine, value: form.addressLine, onChange: handleInputFieldChange }), _jsx(Input, { required: true, id: 'city', dataTestId: 'city', name: 'city', label: translate('City'), width: 'lg', hasError: !!errors.city, errorMessage: errors.city, value: form.city, onChange: handleInputFieldChange, onBlur: handleInputFieldBlur }), _jsx(Select, { required: true, dataTestId: 'region', label: translate('Region'), width: 'lg', options: stateOptions, hasError: !!errors.region, errorMessage: errors.region, value: getSelectValue(stateOptions, form.region), onChange: (value) => handleSelectFieldChange('region', value) }), _jsx(Input, { id: 'postalCode', dataTestId: 'postal-code', name: 'postalCode', label: translate('ZIP code'), width: 'lg', hasError: !!errors.postalCode, errorMessage: errors.postalCode, value: form.postalCode, onChange: handleInputFieldChange, onBlur: handleInputFieldBlur })] }));
    };
    const formSections = [
        {
            title: translate('Details'),
            content: getDetailsSectionContent(),
        },
        {
            title: translate('Contact information'),
            content: getContactInformationSectionContent(),
        },
    ];
    return (_jsx(_Fragment, { children: _jsx(ModalWindow, { isOpen: isOpen, dataTestId: 'create-site-modal', title: isEditMode ? translate('Edit site') : translate('Create site'), subtitle: isEditMode ? workspaceSite?.name : translate('Create workspace site'), closeWindow: onClose, width: 'full', footerPrimaryActionButton: {
                id: 'submit-button',
                dataTestId: 'submit-button',
                disabled: isSubmitButtonDisabled(),
                label: isEditMode ? translate('Done') : translate('Create site'),
                content: isPending ? _jsx(Spinner, { size: 'sm' }) : undefined,
                onClick: handleSubmitForm,
            }, footerSecondaryActionButtons: [
                {
                    id: 'cancel-button',
                    dataTestId: 'cancel-button',
                    label: translate('Cancel'),
                    onClick: handleClose,
                },
            ], children: _jsx("form", { className: 'mt-10 mb-10', children: _jsx(FormSections, { sections: formSections }) }) }) }));
};
