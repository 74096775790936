import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useContext } from 'react';
import { RosettaSDKUtils } from '@axon/rosetta-sdk';
import { FirebaseAppContext } from 'libs.firebase_react';
import { Button, FormSections, useLocalizeMessage } from 'libs.nucleus';
import { ListInformation, useBooleanState } from 'libs.react';
import { TranslationStartedNotification } from 'libs.translation_workbench';
import { StudyParticipantDetailsEditModal } from './study_participant_details_edit_modal.component';
import { StudyParticipantStatuses } from './study_participant_statuses';
import { SELF_SERVICE_INFO_TYPES } from '../../../constants';
import { StudyConfigurationContext } from '../../../contexts';
export const StudyParticipantDetails = () => {
    const translate = useLocalizeMessage();
    const { assignStudy, currentStudy, isReadOnlyMode, studyId } = useContext(StudyConfigurationContext);
    const [isEditDetailsModalOpen, openEditDetailsModal, closeEditDetailsModal] = useBooleanState(false);
    const { logEvent } = useContext(FirebaseAppContext);
    const studyMetadata = currentStudy.studyMetadata || RosettaSDKUtils.getDefaultStudyMetadata();
    const handleDetailsSaveEdit = (newStudyMetadata) => {
        currentStudy.updateStudyMetadata(newStudyMetadata);
        currentStudy.validateBuiltInActivities();
        assignStudy(currentStudy);
        logEvent('study_participant_details_updated', { study_id: studyId });
        closeEditDetailsModal();
    };
    const getParticipantIdGenerationMethodDescription = () => {
        return studyMetadata.subjectIdAsATask ? translate('Site input') : translate('Integration');
    };
    const detailItems = [
        {
            title: translate('Participant number creation'),
            dataTestId: 'participant-id-generation-method',
            description: getParticipantIdGenerationMethodDescription(),
        },
        { title: translate('Participant number format'), description: studyMetadata.subjectIdFormat },
        {
            title: translate('Registration method'),
            description: studyMetadata.isNoPIIEnabled ? translate('Username') : translate('Email'),
        },
    ];
    if (studyMetadata.isNoPIIEnabled) {
        detailItems.push({
            dataTestId: 'support-information',
            title: translate('Support information'),
            description: studyMetadata.selfServiceInformation[SELF_SERVICE_INFO_TYPES.SUPPORT],
        });
    }
    else if (studyMetadata.authentication) {
        const description = [
            studyMetadata.authentication.firstName && translate('First and last name'),
            studyMetadata.authentication.phoneNumber && translate('Phone number'),
        ].filter(Boolean);
        detailItems.push({ title: translate('Account creation information'), description });
    }
    const formSections = [
        {
            title: translate('Details'),
            content: (_jsxs("section", { className: 'flex flex-col gap-6', children: [_jsx("div", { className: 'ml-auto', children: _jsx(Button, { label: translate('Edit details'), onClick: openEditDetailsModal, variant: 'secondary', disabled: isReadOnlyMode }) }), _jsx(ListInformation, { dataTestId: 'participant-details-list', items: detailItems, useDefaultEmptyDescription: true })] })),
        },
        {
            title: translate('Participant statuses'),
            content: _jsx(StudyParticipantStatuses, {}),
        },
    ];
    return (_jsxs(_Fragment, { children: [isReadOnlyMode && _jsx(TranslationStartedNotification, {}), _jsx(FormSections, { sections: formSections }), isEditDetailsModalOpen && (_jsx(StudyParticipantDetailsEditModal, { onCancel: closeEditDetailsModal, onSave: handleDetailsSaveEdit, initialMetadata: studyMetadata }))] }));
};
