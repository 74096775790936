import { ONLY_POSITIVE_INTEGERS_REGEX } from '../../constants';
/**
 * Check if two strings match
 * @param str1 The first string
 * @param str2 The second string
 * @param sanitize Whether to sanitize the strings before comparing
 * @returns Whether the strings match or not
 */
const isSameString = (str1, str2, sanitize = true) => {
    if (!str1 || !str2) {
        return false;
    }
    return sanitize ? str1.toLowerCase().trim() === str2.toLowerCase().trim() : str1 === str2;
};
const sortBy = (items, key) => {
    items.sort((itemA, itemB) => {
        const valueA = itemA[key];
        const valueB = itemB[key];
        if (typeof valueA === 'string' && typeof valueB === 'string') {
            return valueA.localeCompare(valueB);
        }
        return valueA < valueB ? -1 : valueA > valueB ? 1 : 0;
    });
    return items;
};
const isPositiveInputNumberValid = (value, maxValue, props = {}) => {
    return (!value ||
        (props.allowZero && Number(value) === 0) ||
        (ONLY_POSITIVE_INTEGERS_REGEX.test(value) && Number(value) <= maxValue && Number(value) > 0));
};
export const GenericUtils = {
    isPositiveInputNumberValid,
    isSameString,
    sortBy,
};
