import { isEmpty } from 'lodash';
import { isEmail, isMobilePhone } from 'validator';
export const validateFormFieldOnBlur = (fieldName, value) => {
    switch (fieldName) {
        case 'name': {
            // eslint-disable-next-line no-control-regex
            const pattern = /^[0-9]|[\u0007\u0008\u0009\u000A\u000B\u000C\u000D]/;
            if (!value) {
                return 'Name is required';
            }
            if (value.length > 100) {
                return 'Maximum 100 characters';
            }
            if (pattern.test(value)) {
                return "Can't start with a number or have non-printable characters";
            }
            break;
        }
        case 'email': {
            if (!isEmpty(value) && !isEmail(value)) {
                return 'Invalid email address';
            }
            break;
        }
        case 'phone': {
            if (isEmpty(value)) {
                return 'Phone is required';
            }
            if (!isMobilePhone(value)) {
                return 'Invalid phone number';
            }
            break;
        }
        case 'addressLine': {
            if (isEmpty(value)) {
                return 'Address line is required';
            }
            break;
        }
        case 'city': {
            if (isEmpty(value)) {
                return 'City is required';
            }
            break;
        }
        case 'countryCode': {
            if (isEmpty(value)) {
                return 'Country is required';
            }
            break;
        }
        case 'region': {
            if (isEmpty(value)) {
                return 'Region is required';
            }
            break;
        }
        default:
            return undefined;
    }
    return undefined;
};
export const validateFormFieldOnChange = (fieldName, value) => {
    switch (fieldName) {
        case 'name': {
            // eslint-disable-next-line no-control-regex
            const pattern = /^[0-9]|[\u0007\u0008\u0009\u000A\u000B\u000C\u000D]/;
            if (value && value.length > 100) {
                return 'Maximum 100 characters';
            }
            if (pattern.test(value)) {
                return "Can't start with a number or have non-printable characters";
            }
            break;
        }
        default:
            return undefined;
    }
};
// todo: remove all options above and replace with real data from be
// export const therapeuticAreasOptions: SelectOption[] = [
//   { label: 'Psoriasis', value: 'Psoriasis' },
//   { label: 'Rheumatoid Arthritis', value: 'Rheumatoid Arthritis' },
//   { label: 'Cardiovascular', value: 'Cardiovascular' },
//   { label: 'Heart Failure', value: 'Heart Failure' },
//   { label: 'QT Studies', value: 'QT Studies' },
//   {
//     label: 'Traditional Chinese Medicine - Coronary Artery Disease-Angina',
//     value: 'Traditional Chinese Medicine - Coronary Artery Disease-Angina',
//   },
//   { label: 'Acute Kidney Injury', value: 'Acute Kidney Injury' },
//   { label: 'Diabetes', value: 'Diabetes' },
//   { label: 'Diabetes Type 1 - Exercise and Nutrition', value: 'Diabetes Type 1 - Exercise and Nutrition' },
//   { label: 'Diabetes Type 1 - Pediatrics and Devices', value: 'Diabetes Type 1 - Pediatrics and Devices' },
//   {
//     label: 'Diabetes Type 1 - Screening, Staging and Monitoring of Pre-clinical',
//     value: 'Diabetes Type 1 - Screening, Staging and Monitoring of Pre-clinical',
//   },
//   { label: 'Type 1 Diabetes', value: 'Type 1 Diabetes' },
//   { label: 'Diabetic Kidney Disease', value: 'Diabetic Kidney Disease' },
//   { label: 'Dyslipidemia', value: 'Dyslipidemia' },
//   { label: 'Kidney Transplant', value: 'Kidney Transplant' },
//   { label: 'Polycystic Kidney Disease', value: 'Polycystic Kidney Disease' },
//   { label: 'CDAD', value: 'CDAD' },
//   { label: "Crohn's Disease", value: "Crohn's Disease" },
//   { label: 'COVID-19', value: 'COVID-19' },
//   { label: 'Ebola', value: 'Ebola' },
//   { label: 'Hepatitis C', value: 'Hepatitis C' },
//   { label: 'HIV', value: 'HIV' },
//   { label: 'Influenza', value: 'Influenza' },
//   { label: 'Malaria', value: 'Malaria' },
//   { label: 'Tuberculosis', value: 'Tuberculosis' },
//   { label: 'Virology', value: 'Virology' },
//   { label: 'Major Depressive Disorder', value: 'Major Depressive Disorder' },
//   { label: 'Post Traumatic Stress Disorder', value: 'Post Traumatic Stress Disorder' },
//   { label: 'Schizophrenia', value: 'Schizophrenia' },
//   { label: "Alzheimer's", value: "Alzheimer's" },
//   { label: "Huntington's Disease", value: "Huntington's Disease" },
//   { label: 'Multiple Sclerosis', value: 'Multiple Sclerosis' },
//   { label: "Parkinson's Disease", value: "Parkinson's Disease" },
//   { label: 'Traumatic Brain Injury', value: 'Traumatic Brain Injury' },
//   { label: 'Breast Cancer', value: 'Breast Cancer' },
//   { label: 'Colorectal Cancer', value: 'Colorectal Cancer' },
//   { label: 'Lung Cancer', value: 'Lung Cancer' },
//   { label: 'Pancreatic Cancer', value: 'Pancreatic Cancer' },
//   { label: 'Prostate Cancer', value: 'Prostate Cancer' },
//   { label: 'Nutrition', value: 'Nutrition' },
//   { label: 'Pediatrics', value: 'Pediatrics' },
//   { label: 'Traditional Chinese Medicine - Acupuncture', value: 'Traditional Chinese Medicine - Acupuncture' },
//   { label: 'Duchenne Muscular Dystrophy', value: 'Duchenne Muscular Dystrophy' },
//   { label: 'Rare Diseases', value: 'Rare Diseases' },
//   { label: 'Asthma', value: 'Asthma' },
//   { label: 'COPD', value: 'COPD' },
//   { label: 'Pain', value: 'Pain' },
//   { label: 'Vaccines', value: 'Vaccines' },
// ];
export const countryOptions = [
    { label: 'United States of America', value: 'US' },
    { label: 'Canada', value: 'CA' },
    { label: 'China', value: 'CN' },
    { label: 'France', value: 'FR' },
    { label: 'Germany', value: 'DE' },
    { label: 'Italy', value: 'IT' },
    { label: 'Japan', value: 'JP' },
    { label: 'Mexico', value: 'MX' },
    { label: 'Russia', value: 'RU' },
    { label: 'United Kingdom', value: 'GB' },
    // Add more countries as needed
];
export const timezoneOptions = [
    { label: 'Pacific Time (US & Canada)', value: 'America/Los_Angeles' },
    { label: 'Mountain Time (US & Canada)', value: 'America/Denver' },
    { label: 'Central Time (US & Canada)', value: 'America/Chicago' },
    { label: 'Eastern Time (US & Canada)', value: 'America/New_York' },
    { label: 'Atlantic Time (Canada)', value: 'America/Halifax' },
    { label: 'Newfoundland Time', value: 'America/St_Johns' },
    { label: 'Hawaii-Aleutian Time (US)', value: 'Pacific/Honolulu' },
    { label: 'Alaska Time (US)', value: 'America/Anchorage' },
    { label: 'Greenwich Mean Time', value: 'Etc/Greenwich' },
    { label: 'Central European Time', value: 'Europe/Berlin' },
    { label: 'Eastern European Time', value: 'Europe/Kiev' },
    { label: 'China Standard Time', value: 'Asia/Shanghai' },
    { label: 'Japan Standard Time', value: 'Asia/Tokyo' },
    { label: 'Australian Eastern Time', value: 'Australia/Sydney' },
    { label: 'Indian Standard Time', value: 'Asia/Kolkata' },
    // Add more timezones as needed
];
export const localesOptions = [
    { label: 'English (United States)', value: 'en_US' },
    { label: 'Spanish (Spain)', value: 'es_ES' },
    { label: 'German (Germany)', value: 'de_DE' },
    { label: 'French (France)', value: 'fr_FR' },
    { label: 'Italian (Italy)', value: 'it_IT' },
    // Add more locales as needed
];
export const stateOptions = [
    { label: 'Alabama', value: 'AL' },
    { label: 'Alaska', value: 'AK' },
    { label: 'Arizona', value: 'AZ' },
    { label: 'Arkansas', value: 'AR' },
    { label: 'California', value: 'CA' },
    { label: 'Colorado', value: 'CO' },
    { label: 'Connecticut', value: 'CT' },
    { label: 'Delaware', value: 'DE' },
    { label: 'Florida', value: 'FL' },
    { label: 'Georgia', value: 'GA' },
    { label: 'Hawaii', value: 'HI' },
    { label: 'Idaho', value: 'ID' },
    { label: 'Illinois', value: 'IL' },
    { label: 'Indiana', value: 'IN' },
    { label: 'Iowa', value: 'IA' },
    { label: 'Kansas', value: 'KS' },
    { label: 'Kentucky', value: 'KY' },
    { label: 'Louisiana', value: 'LA' },
    { label: 'Maine', value: 'ME' },
    { label: 'Maryland', value: 'MD' },
    { label: 'Massachusetts', value: 'MA' },
    { label: 'Michigan', value: 'MI' },
    { label: 'Minnesota', value: 'MN' },
    { label: 'Mississippi', value: 'MS' },
    { label: 'Missouri', value: 'MO' },
    { label: 'Montana', value: 'MT' },
    { label: 'Nebraska', value: 'NE' },
    { label: 'Nevada', value: 'NV' },
    { label: 'New Hampshire', value: 'NH' },
    { label: 'New Jersey', value: 'NJ' },
    { label: 'New Mexico', value: 'NM' },
    { label: 'New York', value: 'NY' },
    { label: 'North Carolina', value: 'NC' },
    { label: 'North Dakota', value: 'ND' },
    { label: 'Ohio', value: 'OH' },
    { label: 'Oklahoma', value: 'OK' },
    { label: 'Oregon', value: 'OR' },
    { label: 'Pennsylvania', value: 'PA' },
    { label: 'Rhode Island', value: 'RI' },
    { label: 'South Carolina', value: 'SC' },
    { label: 'South Dakota', value: 'SD' },
    { label: 'Tennessee', value: 'TN' },
    { label: 'Texas', value: 'TX' },
    { label: 'Utah', value: 'UT' },
    { label: 'Vermont', value: 'VT' },
    { label: 'Virginia', value: 'VA' },
    { label: 'Washington', value: 'WA' },
    { label: 'West Virginia', value: 'WV' },
    { label: 'Wisconsin', value: 'WI' },
    { label: 'Wyoming', value: 'WY' },
];
