import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useQuery } from '@tanstack/react-query';
import { isEmpty } from 'lodash';
import { useContext } from 'react';
import { Button, useLocalizeMessage, Spinner, Table } from 'libs.nucleus';
import { SectionCard } from 'libs.react';
import { buildDocumentCreateUrl } from './participant_documents.utils';
import { useParticipantDocumentsTableColumns } from './participants_documents_table_columns.hook';
import { SiteContext } from '../../contexts/site';
import { getApiInstance } from '../../helpers/api.helpers';
import { useAxonAccountContext } from '../../hooks/use_axon_account_context';
import { useCurrentEnvironment } from '../../hooks/use_current_environment';
export const ParticipantDocuments = ({ participantId }) => {
    const translate = useLocalizeMessage();
    const account = useAxonAccountContext();
    const { site } = useContext(SiteContext);
    const { environment } = useCurrentEnvironment();
    const { participantDocumentsColumns } = useParticipantDocumentsTableColumns();
    const openCreateDocumentPage = () => {
        const createDocumentUrl = buildDocumentCreateUrl(participantId, environment);
        window.open(createDocumentUrl, '_blank');
    };
    const getParticipantDocumentsData = async () => {
        if (!environment) {
            return [];
        }
        try {
            const response = await getApiInstance(environment).get(`/v2/account/${account?._id}/c_sites/${site?._id}/c_subjects/${participantId}/ec__documents`, {
                params: {
                    where: JSON.stringify({ ec__site: site?._id }),
                    paths: ['ec__document_template.ec__title'],
                    include: ['ec__status', 'ec__required_signers', 'created'],
                },
            });
            return response?.data?.data || {};
        }
        catch (error) {
            return {};
        }
    };
    const { data: documents, isLoading } = useQuery({
        queryKey: ['participantDocuments', participantId],
        queryFn: getParticipantDocumentsData,
        enabled: !!participantId,
    });
    if (isLoading) {
        return _jsx(Spinner, { wrapper: 'full' });
    }
    if (isEmpty(documents)) {
        return (_jsx(SectionCard, { children: _jsxs("section", { className: 'flex flex-col items-center justify-center p-10 m-10', children: [_jsx("p", { className: 'mb-8 text-sm text-secondary', children: translate('No documents have been created.') }), _jsx(Button, { label: translate('Create document'), variant: 'secondary', onClick: openCreateDocumentPage })] }) }));
    }
    return (_jsxs("div", { className: 'flex flex-col', children: [_jsx("div", { className: 'flex justify-end', children: _jsx(Button, { label: translate('Create document'), variant: 'secondary', onClick: openCreateDocumentPage }) }), _jsx(Table, { columns: participantDocumentsColumns, data: documents || [], hasPagination: true, initialPageSize: 10, isLoading: isLoading, labelNoResults: translate('No documents'), paginationNavigationScreenReaderLabel: translate('Participant documents table navigation') })] }));
};
