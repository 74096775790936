import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { StudyLocalizationStatus } from '@axon/rosetta-sdk';
import { useLocalizeMessage, ModalWindow, Spinner, Text } from 'libs.nucleus';
export const StudyTranslationStatusModal = ({ onModalClose, onHandleStatusChange, isLoading, currentStatus, }) => {
    const t = useLocalizeMessage();
    const isDraft = currentStatus === StudyLocalizationStatus.DRAFT || currentStatus === StudyLocalizationStatus.NOT_STARTED;
    const doneButton = {
        label: isDraft ? t('Lock build') : t('Unlock build'),
        onClick: onHandleStatusChange,
        variant: 'destructive',
        content: isLoading ? (_jsx("div", { className: 'py-1', children: _jsx(Spinner, { size: 'sm', variant: 'alt' }) })) : undefined,
    };
    const closeButton = {
        label: t('Cancel'),
        onClick: onModalClose,
        // disabled: isLoading, // temperory commented
    };
    return (_jsx(ModalWindow, { title: isDraft ? t('Lock build for translation') : t('Unlock build to modify'), isOpen: true, closeWindow: onModalClose, footerSecondaryActionButtons: [closeButton], footerPrimaryActionButton: doneButton, position: 'top', children: isDraft ? (_jsxs("div", { className: 'min-h-80', children: [_jsx(Text, { className: 'text-secondary', children: t('You are about to lock all assets to start translations.') }), _jsxs("ul", { className: 'list-disc ps-7 leading-8 marker:text-secondary', children: [_jsx("li", { children: _jsx(Text, { className: 'text-secondary', children: t('All edit screens will be read-only, which will be indicated with a banner.') }) }), _jsx("li", { children: _jsx(Text, { className: 'text-secondary', children: t('Non-library assets will be set to v1.') }) }), _jsx("li", { children: _jsx(Text, { className: 'text-secondary', children: t('Translation can get started.') }) }), _jsx("li", { children: _jsx(Text, { className: 'text-secondary', children: t('To edit the assets, you will have to Unlock the build.') }) }), _jsx("li", { children: _jsx(Text, { className: 'text-secondary', children: t('Editing could invalidate related translations.') }) })] })] })) : (_jsxs("div", { className: 'min-h-80', children: [_jsx(Text, { className: 'text-secondary', children: t('You are about to unlock all assets for modification.') }), _jsxs("ul", { className: 'list-disc ps-7 leading-8 marker:text-secondary', children: [_jsx("li", { children: _jsx(Text, { className: 'text-secondary', children: t('All previously disabled edit sections will become editable.') }) }), _jsx("li", { children: _jsx(Text, { className: 'text-secondary', children: t('Be aware that editing may invalidate your current translations progress.') }) })] })] })) }));
};
