import { jsx as _jsx } from "react/jsx-runtime";
import { RadioGroup, RadioGroupOption, Spinner, useLocalizeMessage } from 'libs.nucleus';
import { useEnvironments } from '../../hooks';
// todo: make ui compatible with the design - see discussion https://medable.slack.com/archives/C056G8HLWBZ/p1730229534482529
export const SelectEnvironmentStep = ({ value, onChange }) => {
    const translate = useLocalizeMessage();
    const { isLoading, environmentOptions } = useEnvironments();
    if (isLoading) {
        return _jsx(Spinner, { wrapper: 'full' });
    }
    return (_jsx(RadioGroup, { value: value, onChange: onChange, label: translate('Select environment'), children: environmentOptions.map((option) => (_jsx(RadioGroupOption, { option: {
                label: option.label,
                value: option.value,
                dataTestId: `environment-${option.label}`,
            } }, option.value))) }));
};
