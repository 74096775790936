import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useContext } from 'react';
import { useMatch } from 'react-router-dom';
import { useLocalizeMessage, Tabs, useDialog, Spinner } from 'libs.nucleus';
import { PageHeader, PageWrapper, useBooleanState, useTabNavigation, useToastNotification, } from 'libs.react';
import { ParticipantDetails } from '../../components/participant_details/participant_details.component';
import { ParticipantDocuments } from '../../components/participant_documents/participant_documents.component';
import { ParticipantVisits } from '../../components/participant_visits/participant_visits.component';
import { SiteContext } from '../../contexts/site';
import { getApiErrorMessage, getApiInstance } from '../../helpers/api.helpers';
import { getParticipantDetails, isParticipantAuthenticated } from '../../helpers/participants.helpers';
import { useCurrentEnvironment } from '../../hooks/use_current_environment';
import { EParticipantInviteStatus } from '../../types/participants.type';
export const ParticipantDetailsPage = () => {
    const translate = useLocalizeMessage();
    const { environment } = useCurrentEnvironment();
    const { site } = useContext(SiteContext);
    const [isBusy, setIsbusy, unsetIsBusy] = useBooleanState(false);
    const match = useMatch('/site/study/:studyId/participants/:participantId');
    const participantId = match?.params.participantId;
    const { addNotification } = useToastNotification();
    const dialog = useDialog();
    const queryClient = useQueryClient();
    const [activeTab, setActiveTab] = useTabNavigation(['#details', '#visits', '#documents', '#audit-history']);
    const getParticipantData = async () => {
        if (!environment) {
            return {};
        }
        return await getParticipantDetails({
            environment,
            siteId: site?._id ?? '',
            participantId: participantId ?? '',
        });
    };
    const handleInviteCancel = async () => {
        const ok = await dialog.open({
            title: translate('Cancel Participant Invite'),
            body: translate("Are you sure you want to cancel this participant's invite"),
            actionButtons: [{ isDestructive: true, label: translate('Yes, cancel') }, { label: translate('No, return') }],
        });
        console.log('OK', ok);
        if (ok && environment) {
            try {
                setIsbusy();
                await getApiInstance(environment).post('/routes/cancel_invite', {
                    c_site: site?._id,
                    c_public_user: participantId,
                });
                addNotification({
                    title: '',
                    subtitle: translate('Participant invite has been canceled.'),
                    type: 'success',
                });
                queryClient.invalidateQueries({
                    queryKey: ['participantDetails', participantId],
                });
            }
            catch (e) {
                const message = getApiErrorMessage(e);
                addNotification({
                    title: '',
                    subtitle: message ?? translate('We were unable to process your request. Try again later.'),
                    type: 'error',
                });
            }
            finally {
                unsetIsBusy();
            }
        }
    };
    const { data: participantData, isLoading } = useQuery({
        queryKey: ['participantDetails', participantId],
        queryFn: getParticipantData,
        enabled: true,
    });
    const breadcrumbsPages = [
        { name: translate('Participants'), path: `/site/study/${environment?.id}/participants` },
        { name: participantData?.c_number || translate('Participant Detail'), path: '' },
    ];
    const headerOptions = [];
    //  If participant isnt authenticated and invite is not expired, show cancel invite button
    if (!isParticipantAuthenticated(participantData) && participantData?.c_invite !== EParticipantInviteStatus.EXPIRED) {
        headerOptions.push({ name: translate('Cancel invite'), onClick: handleInviteCancel, variant: 'secondary' });
    }
    return (_jsxs(PageWrapper, { children: [_jsx(PageHeader, { breadcrumbPages: breadcrumbsPages, title: participantData?.c_number || '', options: headerOptions }), isBusy && _jsx(Spinner, { wrapper: 'full' }), _jsx(Tabs, { tabLabels: [translate('Details'), translate('Visits'), translate('Documents'), translate('Audit History')], tabContent: [
                    _jsx(ParticipantDetails, { participant: participantData, participantDetailsLoading: isLoading }, 'participant-details'),
                    _jsx(ParticipantVisits, { participantNumber: participantData?.c_number || '' }, 'participant-visits'),
                    _jsx(ParticipantDocuments, { participantId: participantId }, 'participant-documents'),
                    _jsx("p", { children: "Participant Audit History go here" }, 'participant-audit-history'),
                ], defaultIndex: activeTab, onTabChange: setActiveTab })] }));
};
