import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useLocalization, useStudyL10nMap } from 'libs.localization.react';
import { useLocalizeMessage, ModalWindow, RadioGroup, RadioGroupOption, Spinner, Table, Text, } from 'libs.nucleus';
import { TranslationWorkbenchContext } from '../../contexts';
import { useLibraryDetails } from '../../hooks';
import { toSentenceCase } from '../../utils/string_utils';
import { AssetVersion } from '../asset_version/asset_version.component';
export const AssetVersionSelectionModal = ({ onModalClose, data, asset, displayAssetName, }) => {
    const t = useLocalizeMessage();
    const { studyId, assetId } = useParams();
    const { data: library } = useLibraryDetails(assetId);
    const { study, updateStudy, setL10nMap } = useContext(TranslationWorkbenchContext);
    const [versions, setVersions] = useState([]);
    const { isPending: isSettingStudyL10nMap, setStudyL10nMap } = useStudyL10nMap();
    const { localization } = useLocalization(asset);
    const currentStatus = data.currentVersionStatus?.status || data.status;
    const resource = library?.data.data.data.activity || library?.data.data.data;
    useEffect(() => {
        if (localization && versions.length === 0) {
            (async () => {
                const _history = await localization?.getLocaleHistory(data.locale);
                const sortedHistory = _history?.sort((a, b) => (a?.version ?? 0) - (b?.version ?? 0)); // sorted numeric ascending
                if (_history) {
                    setVersions(sortedHistory?.map((h) => ({ version: Number(h.version), status: h.status })) || []);
                }
            })();
        }
    }, [localization]);
    const [selectedVersion, setSelectedVersion] = useState(`${data.currentVersionStatus?.version || data.version}-${currentStatus}`);
    const onChangeClick = useCallback(async () => {
        if (selectedVersion && asset && studyId) {
            const currentL10nMapId = study.l10nMapResourceId;
            const assetLocaleVersionSet = [
                {
                    id: localization?.getId() || '',
                    locale: data.locale,
                    version: Number(selectedVersion.split('-')[0]),
                },
            ];
            setStudyL10nMap({ studyId: parseInt(studyId, 10), currentL10nMapId, assetLocaleVersionSet }, {
                onSuccess: (response) => {
                    if (response?.data?.id) {
                        study.l10nMapResourceId = response.data.id;
                        updateStudy?.(study);
                        onModalClose();
                    }
                    if (response?.data?.data.data.mapping) {
                        setL10nMap?.(response?.data?.data.data.mapping);
                    }
                },
            });
        }
    }, [selectedVersion, data]);
    const doneButton = {
        label: t('Change version'),
        onClick: onChangeClick,
        disabled: selectedVersion === `${data.currentVersionStatus?.version || data.version}-${currentStatus}`,
        content: isSettingStudyL10nMap ? (_jsx("div", { className: 'py-1', children: _jsx(Spinner, { size: 'sm', variant: 'alt' }) })) : undefined,
    };
    const closeButton = {
        label: t('Cancel'),
        onClick: onModalClose,
        disabled: isSettingStudyL10nMap,
    };
    const columns = useMemo(() => {
        return [
            {
                header: t('Version'),
                cell: ({ row }) => {
                    return (_jsxs("div", { className: 'flex w-full items-center gap-4', children: [_jsx(RadioGroupOption, { option: {
                                    value: `${row.original.version}-${row.original.status}`,
                                    label: '',
                                } }), _jsx(AssetVersion, { currentVersion: row.original }), _jsx("span", { className: 'flex-1' })] }));
                },
            },
        ];
    }, [versions]);
    return (_jsx(ModalWindow, { isOpen: true, title: t('View or change version'), position: 'top', closeWindow: onModalClose, footerSecondaryActionButtons: [closeButton], footerPrimaryActionButton: doneButton, children: _jsxs("div", { children: [_jsxs("div", { "data-testid": 'asset_version_selection_modal_description', children: [_jsx(Text, { as: 'span', children: t('Here are the available versions for ') }), _jsx(Text, { as: 'span', className: 'font-medium', children: resource?.full_name || displayAssetName }), _jsxs(Text, { as: 'span', children: [" ", t('locale'), " "] }), _jsx(Text, { as: 'span', className: 'font-medium', children: data.locale }), _jsx(Text, { as: 'span', children: t('. If you change version, the operation is reversible.') }), _jsx(Text, { as: 'span', children: t(' Your current version is ') }), _jsxs(Text, { as: 'span', className: 'font-medium', children: ["v", data.currentVersionStatus?.version || data.version || 1, ' ', toSentenceCase(currentStatus)] })] }), _jsx("hr", { className: 'mt-6' }), versions && (_jsx(RadioGroup, { value: selectedVersion, onChange: setSelectedVersion, "data-testid": 'asset_version_selection_modal_radio', children: _jsx(Table, { data: versions, columns: columns, labelNoResults: t('No versions available') }) }))] }) }));
};
