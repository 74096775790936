import { jsx as _jsx } from "react/jsx-runtime";
import { useLocalizeMessage, Table } from 'libs.nucleus';
import { useWorkspaceSiteColumns } from './workspace_sites_columns.hook';
import { useFetchWorkspaceSites } from '../../hooks/useWorkspaceSites.hook';
// todo: FYI: data sorting & filtering functionality can create screen flashes between loading & data states
// todo: q: are we plan to use be_sorting?
// todo: q: are we plan to use be_filtering?
// todo: add search functionality (how to show search input with useInternalSearch=false?)
export const WorkspaceSitesTable = () => {
    const translate = useLocalizeMessage();
    const { workspaceSiteColumns } = useWorkspaceSiteColumns();
    const initialPagination = { pageIndex: 0, pageSize: 10 };
    const { isLoading, workspaceSites, pagination, totalPages, onPaginationChange } = useFetchWorkspaceSites(initialPagination);
    return (_jsx(Table
    // main props
    , { "data-testid": 'workspace-sites-table', isLoading: isLoading, isSelectable: false, hasPagination: true, manualPagination: true, manualPaginationState: pagination, initialPageSize: 10, pageCount: totalPages, paginationNavigationScreenReaderLabel: translate('Sites table navigation'), labelNoResults: translate('No Sites'), columns: workspaceSiteColumns, data: workspaceSites || [], onPaginationChange: onPaginationChange }));
};
