import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useContext } from 'react';
import { Link as RouterLink, useMatch } from 'react-router-dom';
import { Button, useLocalizeMessage, LocaleContext, Link } from 'libs.nucleus';
import { getRouteLink } from '../../helpers/links.helpers';
import { formatVisitWindow } from '../../helpers/participants.helpers';
import { useCurrentEnvironment } from '../../hooks/use_current_environment';
import { Card } from '../card';
export const VisitSchedule = ({ participantVisits, setCurrentVisit, setIsActivityListModalOpen, }) => {
    const translate = useLocalizeMessage();
    const currentLocale = useContext(LocaleContext);
    const { environment } = useCurrentEnvironment();
    const match = useMatch('/site/study/:studyId/participants/:participantId');
    const participantId = match?.params.participantId;
    return (_jsx(_Fragment, { children: participantVisits.map((visit) => {
            return (_jsx(Card, { children: _jsxs("div", { className: 'flex flex-col gap-4', children: [_jsx("div", { className: 'flex gap-4', children: _jsx(Link, { LinkComponent: RouterLink, to: getRouteLink(`participants/${participantId}/visit/${visit.c_visit}`, environment), "data-testid": 'visit-name', children: visit.c_name }) }), _jsxs("div", { className: 'flex gap-2 items-center', children: [_jsx("span", { className: 'text-gray-500', "data-testid": `visit-period-${visit.c_visit}`, children: formatVisitWindow({
                                        windowStartDate: visit.c_window_start_date,
                                        windowEndDate: visit.c_window_end_date,
                                        currentLocale,
                                    }) }), _jsx("span", { children: "\u2022" }), _jsx("span", { className: 'text-gray-500', "data-testid": `visit-assessments-${visit.c_visit}`, children: translate('{assignments} assessments', { assignments: visit.assignments }) }), _jsx("span", { className: 'ml-auto', children: _jsx(Button, { label: translate('Start visit'), onClick: () => {
                                            setCurrentVisit(visit);
                                            setIsActivityListModalOpen();
                                        } }) })] })] }, visit.c_visit) }, visit.c_visit));
        }) }));
};
