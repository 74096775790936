import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext } from 'react';
import { FlagContext } from 'libs.flags.react';
import { Button, Icon, useLocalizeMessage } from 'libs.nucleus';
import { ModalNav } from 'libs.react';
import { TRANSLATION_MANAGEMENT_FLAGS } from '../../utils';
import { GenTranslationMultiSelectionModal } from '../gen_translation_modal';
export const GenerateTranslationMultiAction = ({ assets, hideLocales = [], locales, isDisabled = false, }) => {
    const translate = useLocalizeMessage();
    const flagManager = useContext(FlagContext);
    const isAITranslationEnabled = flagManager.getValue(TRANSLATION_MANAGEMENT_FLAGS.aiTranslationFlag);
    if (!isAITranslationEnabled) {
        return null;
    }
    const noop = () => { };
    return (_jsx(ModalNav, { actionButton: _jsx(Button, { variant: 'secondary', label: translate('Generate Translation ✨'), disabled: isDisabled, content: _jsxs("span", { className: 'font-sans m-0 text-sm', children: [_jsx(Icon, { name: 'sparklesSolid', color: 'primary' }), " ", translate('Generate Translation')] }) }), modalWindow: _jsx(GenTranslationMultiSelectionModal, { hideLocales: hideLocales, assets: assets, locales: locales }), onSubmit: noop }));
};
