import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useContext, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useLocalizeMessage } from 'libs.nucleus';
import { ApiClientService, AuthContext, LibraryEndpoint, useApiClient, useToastNotification, } from 'libs.react';
import { StudyConfigurationContext } from '../study_configuration';
export const StudyTestPlanContext = createContext({
    testPlans: [],
    setTestPlans: () => { },
    fetchTestPlan: async () => {
        return undefined;
    },
    fetchTestPlans: async () => { },
    updateTestPlan: () => { },
    deleteTestPlan: () => { },
    executeTestPlan: () => { },
    deleteExecutionHistory: () => { },
    getTestPlanHistory: async () => { },
    testPlanHistory: {},
    fetchReportUrl: () => Promise.resolve(''),
});
export const StudyTestPlanProvider = ({ children }) => {
    const { addNotification } = useToastNotification();
    const translate = useLocalizeMessage();
    const { entityId } = useContext(AuthContext);
    const { studyId } = useContext(StudyConfigurationContext);
    const autobuildClient = useApiClient(ApiClientService.AUTOBUILD);
    const momClient = useApiClient(ApiClientService.MOM);
    const libClient = useApiClient(ApiClientService.LIBRARY);
    const [testPlans, setTestPlans] = useState([]);
    const [testPlanHistory, setTestPlanHistory] = useState({});
    const getCortexToken = async (envId) => {
        const { data } = await momClient.post(`/v1/entities/${entityId}/studies/${studyId}/environments/${envId}/cortexToken`);
        return data.data;
    };
    const updateTestPlan = (testPlan) => {
        setTestPlans((prev) => prev.map((tp) => {
            if (tp.id === testPlan.id) {
                return testPlan;
            }
            return tp;
        }));
    };
    const deleteTestPlan = async (testPlanId) => {
        const index = testPlans.findIndex((plan) => plan.id === testPlanId);
        const testPlan = { ...testPlans[index] };
        if (index !== -1) {
            setTestPlans((prev) => prev.filter((plan) => plan.id !== testPlanId));
        }
        try {
            const endpoint = `${LibraryEndpoint.GET_TEST_PLANS}/${testPlanId}`;
            await libClient.delete(endpoint);
            addNotification({
                type: 'success',
                title: 'Test plan deleted',
            });
        }
        catch (error) {
            addNotification({
                type: 'error',
                title: 'Error deleting test plan',
            });
            // Restore the test plan if the deletion fails
            setTestPlans((prev) => [...prev.slice(0, index), testPlan, ...prev.slice(index)]);
        }
    };
    const addHistoryEntry = async (testPlanId, sandboxId, sandboxName, autobuildRequestId, sandboxVersion) => {
        const { data: response } = await libClient.post(`${LibraryEndpoint.GET_TEST_REPORTS}`, {
            name: 'test-plan-execution',
            key: uuidv4(),
            studyId,
            metadata: {
                sandboxId,
                sandboxName,
                sandboxVersion,
                testPlanId,
                autobuildRequestId,
            },
            status: 'RUNNING',
        });
        const historyEntry = response.data;
        const newTestPlanHistory = { ...testPlanHistory };
        newTestPlanHistory[testPlanId] = [historyEntry, ...(newTestPlanHistory[testPlanId] || [])];
        setTestPlanHistory({ ...newTestPlanHistory });
        return historyEntry;
    };
    const updateErrorHistoryEntry = async (testPlanId, historyEntryId, error) => {
        const { data: response } = await libClient.put(`${LibraryEndpoint.GET_TEST_REPORTS}/${historyEntryId}`, {
            status: 'ERROR',
            data: {
                error,
            },
        });
        setTestPlanHistory((prev) => ({
            ...prev,
            [testPlanId]: prev[testPlanId].map((history) => {
                if (history.id === historyEntryId) {
                    return response.data;
                }
                return history;
            }),
        }));
    };
    const uploadReport = async (testPlanId, historyEntryId, status, file) => {
        const formData = new FormData();
        formData.append('file', file, `test-report-${historyEntryId}.pdf`);
        formData.append('status', status);
        const { data: response } = await libClient.put(`${LibraryEndpoint.GET_TEST_REPORTS}/${historyEntryId}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        setTestPlanHistory((prev) => ({
            ...prev,
            [testPlanId]: prev[testPlanId].map((history) => {
                if (history.id === historyEntryId) {
                    return response.data;
                }
                return history;
            }),
        }));
    };
    const deleteExecutionHistory = async (historyEntryId) => {
        await libClient.delete(`${LibraryEndpoint.GET_TEST_REPORTS}/${historyEntryId}`);
        const newHistory = {};
        for (const testPlanId in testPlanHistory) {
            newHistory[testPlanId] = testPlanHistory[testPlanId].filter((history) => history.id !== historyEntryId);
        }
        setTestPlanHistory(newHistory);
    };
    const generateTokenForTestPlan = async (sandboxId) => {
        return getCortexToken(sandboxId);
    };
    const executeTestPlan = async (testPlanId, csv, sandboxId, sandboxName, sandboxVersion) => {
        // create a new history entry
        let historyEntry = null;
        try {
            const token = await generateTokenForTestPlan(sandboxId);
            const blob = new Blob([csv], { type: 'text/csv' });
            // Create FormData object
            const formData = new FormData();
            formData.append('file', blob, 'test-plan.csv');
            formData.append('token', token);
            const { data: { requestId }, } = await autobuildClient.post('/testplan/execute/csv', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            historyEntry = await addHistoryEntry(testPlanId, sandboxId, sandboxName, requestId, sandboxVersion);
            const job = await pollExecutionStatus(requestId, token);
            if (job.status === 'ERROR') {
                updateErrorHistoryEntry(historyEntry.metadata.testPlanId, historyEntry.id, job.error);
            }
            const file = await fetchAndSaveTestReport(requestId, token);
            await uploadReport(testPlanId, historyEntry.id, job.status, file);
        }
        catch (e) {
            console.error(e);
            if (historyEntry) {
                updateErrorHistoryEntry(testPlanId, historyEntry.id, e.toString());
            }
        }
    };
    // Function to check status(every 5s) until completed or timeout(5 mins)
    const pollExecutionStatus = async (requestId, token, pollInterval = 5 * 1000, timeout = 5 * 60 * 1000) => {
        return new Promise((resolve, reject) => {
            const interval = setInterval(async () => {
                try {
                    const resp = await autobuildClient.post('/testplan/execute/csv/status', { requestId, token });
                    const { status } = resp.data;
                    if (status?.match(/completed|error/i)) {
                        clearInterval(interval); // Stop polling
                        clearTimeout(timeoutId); // Clear the timeout
                        if (!resp.data.testResults?.failed) {
                            resolve({ status: 'PASSED' });
                        }
                        else {
                            resolve({ status: 'FAILED', error: resp.data.testResults?.error });
                        }
                    }
                }
                catch (error) {
                    clearInterval(interval);
                    clearTimeout(timeoutId);
                    resolve({ status: 'ERROR', error: error.toString() });
                }
            }, pollInterval);
            // Set timeout to reject if polling takes too long
            const timeoutId = setTimeout(() => {
                clearInterval(interval); // Stop polling on timeout
                reject(new Error('Test plan execution took too long'));
            }, timeout);
        });
    };
    // Function to fetch and save the report
    const fetchAndSaveTestReport = async (requestId, token) => {
        const response = await autobuildClient.post('/testplan/execute/csv/report', { requestId, token }, { responseType: 'blob' });
        // Create a Blob from the response data
        const file = new Blob([response.data], { type: 'application/pdf' });
        // update file URL to history entry
        return file;
    };
    const checkDraftedTestPlan = async (history = []) => {
        const draftedTestPlan = history.filter((historyEntry) => historyEntry.status === 'RUNNING');
        if (draftedTestPlan && draftedTestPlan.length > 0) {
            // for each drafted test plan, poll the status
            draftedTestPlan.forEach(async (historyEntry) => {
                try {
                    const token = await generateTokenForTestPlan(historyEntry.metadata.sandboxId);
                    const job = await pollExecutionStatus(historyEntry.metadata.autobuildRequestId, token);
                    if (job.status === 'ERROR') {
                        updateErrorHistoryEntry(historyEntry.metadata.testPlanId, historyEntry.id, job.error);
                    }
                    else {
                        const file = await fetchAndSaveTestReport(historyEntry.metadata.autobuildRequestId, token);
                        await uploadReport(historyEntry.metadata.testPlanId, historyEntry.id, job.status, file);
                    }
                }
                catch (error) {
                    console.error('Error checking drafted test plan', error);
                    updateErrorHistoryEntry(historyEntry.metadata.testPlanId, historyEntry.id, error.toString());
                }
            });
        }
    };
    const getTestPlanHistory = async (testPlanId) => {
        try {
            const { data: response } = await libClient.get(`${LibraryEndpoint.GET_TEST_REPORTS}?studyId=${studyId}&testPlanId=${testPlanId}`);
            setTestPlanHistory((prev) => ({
                ...prev,
                [testPlanId]: response.data || [],
            }));
            checkDraftedTestPlan(response.data);
        }
        catch (error) {
            console.error('Error fetching test plan history', error);
            addNotification({ type: 'error', title: 'Error fetching test plan history' });
        }
    };
    const fetchReportUrl = async (historyEntryId) => {
        try {
            const { data: response } = await libClient.get(`${LibraryEndpoint.GET_TEST_REPORTS}/${historyEntryId}?withUrl=true`);
            return response.data.blobUrl;
        }
        catch (error) {
            console.error('Error fetching blob', error);
            addNotification({ type: 'error', title: 'Error fetching blob' });
            return '';
        }
    };
    const fetchTestPlans = async () => {
        try {
            const { data: response } = await libClient.get(`${LibraryEndpoint.GET_TEST_PLANS}?studyId=${studyId}`);
            setTestPlans(response.data);
        }
        catch (error) {
            console.error('Error fetching test plans', error);
            addNotification({ type: 'error', title: translate('Error fetching test plans') });
        }
    };
    const fetchTestPlan = async (testPlanId) => {
        const testPlan = testPlans ? testPlans.find((testPlan) => testPlan.id === testPlanId) : null;
        if (testPlan) {
            return Promise.resolve(testPlan);
        }
        try {
            const { data: response } = await libClient.get(`${LibraryEndpoint.GET_TEST_PLANS}/${testPlanId}`);
            return response.data;
        }
        catch (error) {
            console.error('Error fetching test plan', error);
            addNotification({ type: 'error', title: translate('Error fetching test plan') });
        }
    };
    return (_jsx(StudyTestPlanContext.Provider, { value: {
            testPlans,
            setTestPlans,
            fetchTestPlan,
            fetchTestPlans,
            updateTestPlan,
            deleteTestPlan,
            executeTestPlan,
            getTestPlanHistory,
            testPlanHistory,
            fetchReportUrl,
            deleteExecutionHistory,
        }, children: children }));
};
