import { jsx as _jsx } from "react/jsx-runtime";
import classNames from 'classnames';
import * as React from 'react';
import SpreadsheetStyles from './spreadsheet_styles';
import { useSpreadsheetDispatch, useSpreadsheetSelector } from '../../contexts/spreadsheet';
const ColumnIndicator = ({ column, label, selected, onSelect }) => {
    const handleClick = React.useCallback((event) => {
        onSelect(column, event.shiftKey);
    }, [onSelect, column]);
    return (_jsx("th", { className: classNames(SpreadsheetStyles.header, {
            [SpreadsheetStyles.headerSelected]: selected,
        }), onClick: handleClick, tabIndex: 0, children: label !== undefined ? label : columnIndexToLabel(column) }));
};
export default ColumnIndicator;
export const enhance = (ColumnIndicatorComponent) => {
    return function ColumnIndicatorWrapper(props) {
        const { selectEntireColumn } = useSpreadsheetDispatch();
        const selected = useSpreadsheetSelector((state) => state.selected.hasEntireColumn(props.column));
        return _jsx(ColumnIndicatorComponent, { ...props, selected: selected, onSelect: selectEntireColumn });
    };
};
function columnIndexToLabel(column) {
    let label = '';
    let index = column;
    while (index >= 0) {
        label = String.fromCharCode(65 + (index % 26)) + label;
        index = Math.floor(index / 26) - 1;
    }
    return label;
}
