import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useQuery, useQueries } from '@tanstack/react-query';
import { useContext, useState } from 'react';
import { useMatch } from 'react-router-dom';
import { useLocalizeMessage, ModalWindow, Spinner } from 'libs.nucleus';
import { SectionCard, useBooleanState, useToastNotification } from 'libs.react';
import { OnboardingSchedule } from './onboarding_schedule.component';
import { VisitSchedule } from './visit_schedule.component';
import { SiteContext } from '../../contexts/site';
import { getApiInstance } from '../../helpers/api.helpers';
import { getParticipantDetails } from '../../helpers/participants.helpers';
import { getOnboardingConfig } from '../../helpers/study.helpers';
import { useCurrentEnvironment } from '../../hooks/use_current_environment';
import { useOrgContext } from '../../hooks/use_org_context';
import { ActivitiesList } from '../activities_list/activities_list.component';
import { ActivityView } from '../activity/activity_modal/activity_view';
export const ParticipantVisits = ({ participantNumber }) => {
    const [isActivityListModalOpen, setIsActivityListModalOpen, setIsActivityListModalClosed] = useBooleanState(false);
    const [isActivityModalOpen, setIsActivityModalOpen, setIsActivityModalClosed] = useBooleanState(false);
    const [visitActivity, setVisitActivity] = useState({});
    const [currentVisit, setCurrentVisit] = useState({});
    const translate = useLocalizeMessage();
    const org = useOrgContext();
    const { addNotification } = useToastNotification();
    const { environment } = useCurrentEnvironment();
    const { site } = useContext(SiteContext);
    const match = useMatch('/site/study/:studyId/participants/:participantId');
    const participantId = match?.params.participantId;
    const onboardingGroupId = getOnboardingConfig(org)?.c_group_id;
    const getParticipantData = async () => {
        if (!environment) {
            return [];
        }
        return await getParticipantDetails({
            environment,
            siteId: site?._id ?? '',
            participantId: participantId ?? '',
        });
    };
    const { data: participantDetails, isLoading: isParticipantLoading } = useQuery({
        queryKey: ['participantDetails', participantId],
        queryFn: getParticipantData,
        enabled: true,
    });
    const participantCNumber = participantNumber || (isParticipantLoading ? '' : participantDetails?.c_number || '');
    const getOnboardingData = async () => {
        if (!environment) {
            return [];
        }
        try {
            const response = await getApiInstance(environment).get(`/v2/routes/c_sites/${site?._id}/c_public_users/${participantId}/c_groups/${onboardingGroupId}/c_task_assignments`);
            return response ? response?.data.data : [];
        }
        catch (error) {
            addNotification({ title: '', subtitle: translate('Error fetching onboarding schedule'), type: 'error' });
        }
    };
    const getParticipantVisitsData = async () => {
        if (!environment) {
            return [];
        }
        try {
            const response = participantCNumber
                ? await getApiInstance(environment).get(`/v2/routes/c_sites/${site?._id}/c_public_users/${participantId}/c_visit_schedule`)
                : null;
            return response ? response?.data.data : [];
        }
        catch (error) {
            addNotification({ title: '', subtitle: translate('Error fetching visit schedule'), type: 'error' });
        }
    };
    const results = useQueries({
        queries: [
            { queryKey: ['onboardingActivities', onboardingGroupId], queryFn: getOnboardingData, enabled: true },
            {
                queryKey: ['participantVisits', participantId, participantCNumber],
                queryFn: getParticipantVisitsData,
                enabled: true,
            },
        ],
    });
    const isLoading = results.some((query) => query.isLoading);
    if (isLoading || isParticipantLoading) {
        return _jsx(Spinner, { wrapper: 'full' });
    }
    const onboardingVisits = results[0].data;
    const participantVisits = results[1].data;
    if (participantVisits.length === 0 && onboardingVisits.length === 0) {
        return (_jsx(SectionCard, { children: _jsx("section", { className: 'flex flex-col items-center justify-center p-10 m-10', children: _jsx("p", { className: 'mb-8 text-sm text-secondary', children: translate('No visits have been created') }) }) }));
    }
    return (_jsxs("div", { className: 'flex flex-col gap-8', children: [_jsx(ModalWindow, { title: currentVisit.c_name || translate('Onboarding Schedule'), subtitle: participantNumber, isOpen: isActivityListModalOpen, hideCloseIcon: true, closeWindow: () => { }, footerSecondaryActionButtons: [
                    {
                        disabled: false,
                        label: translate('Exit Visit'),
                        content: undefined,
                        onClick: () => {
                            setCurrentVisit({});
                            setIsActivityListModalClosed();
                        },
                    },
                ], width: 'full', removeBodyPadding: true, children: _jsx(ActivitiesList, { visit: currentVisit, setVisitActivity: setVisitActivity, setIsActivityModalOpen: setIsActivityModalOpen, ...(currentVisit.c_visit === 'onboarding' && { isOnboarding: true, onboardingSchedule: onboardingVisits }) }) }), _jsx(ModalWindow, { title: visitActivity.activity?.c_group_task ? visitActivity.activity.c_group_task.c_assignment.c_name : '', subtitle: '', isOpen: isActivityModalOpen, hideCloseIcon: true, closeWindow: () => { }, width: 'full', children: _jsx(ActivityView, { visitActivity: visitActivity, setIsActivityModalClosed: setIsActivityModalClosed }) }), _jsx(OnboardingSchedule, { onboardingScheduleActivities: onboardingVisits, setCurrentVisit: setCurrentVisit, setIsActivityListModalOpen: setIsActivityListModalOpen }), participantCNumber && (_jsx(VisitSchedule, { participantVisits: participantVisits, setCurrentVisit: setCurrentVisit, setIsActivityListModalOpen: setIsActivityListModalOpen }))] }));
};
