import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useQuery } from '@tanstack/react-query';
import { useContext, useMemo, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { EM_DASH } from 'libs.constants';
import { Dropdown, useLocalizeMessage, Link, Table, Tooltip, Button } from 'libs.nucleus';
import { FormattedDateTime, ListInformation, PageHeader, useBooleanState } from 'libs.react';
import { EditTestPlanDetailsModal } from './study_edit_test_plan_modal.component';
import { ExecuteTestPlanModal } from './study_execute_test_plan_modal.component';
import { StudyTestPlanExecutionStatus } from './study_test_plan_execution_status.component';
import { StudyConfigurationContext, StudyTestPlanContext } from '../../contexts';
export const StudyTestPlanDetails = ({ testPlan, studyTests }) => {
    const translate = useLocalizeMessage();
    const navigate = useNavigate();
    const { studyId } = useContext(StudyConfigurationContext);
    const { getTestPlanHistory, fetchReportUrl, deleteExecutionHistory, testPlanHistory, updateTestPlan, setTestPlans, deleteTestPlan, } = useContext(StudyTestPlanContext);
    const [isExecuteTestPlanModalOpen, openExecuteTestPlanModal, closeExecuteTestPlanModal] = useBooleanState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(null);
    const testPlanHistoryList = useMemo(() => {
        if (!testPlan) {
            return [];
        }
        return testPlanHistory[testPlan.id] || [];
    }, [testPlan, testPlanHistory]);
    const { isLoading } = useQuery({
        queryKey: [],
        queryFn: () => getTestPlanHistory(testPlan.id),
        enabled: !!testPlan,
    });
    const listItems = useMemo(() => {
        if (!testPlan) {
            return [];
        }
        return [
            { title: translate('Name'), description: testPlan.metadata.name },
            { title: translate('Description'), description: testPlan.data.description },
            {
                title: translate('Sandbox'),
                description: testPlan.metadata.sandboxName,
            },
            {
                title: translate('Date created'),
                description: _jsx(FormattedDateTime, { date: testPlan.createdAt }),
            },
            {
                title: translate('Last updated'),
                description: _jsx(FormattedDateTime, { date: testPlan.updatedAt }),
            },
        ];
    }, [testPlan]);
    const showTestPlan = () => {
        navigate(`/studies/${studyId}/test-plans/${testPlan.id}`);
    };
    const onCloseEditModal = () => {
        setIsEditModalOpen(null);
    };
    const onSaveEditModal = (testPlan) => {
        if (isEditModalOpen === 'edit') {
            updateTestPlan(testPlan);
        }
        else if (isEditModalOpen === 'clone') {
            setTestPlans((prev) => [testPlan, ...prev]);
        }
        setIsEditModalOpen(null);
    };
    const onDelete = () => {
        deleteTestPlan(testPlan.id);
        setIsEditModalOpen(null);
    };
    const executionHistoryColumns = useMemo(() => {
        return [
            {
                header: translate('Status'),
                cell: ({ row }) => _jsx(StudyTestPlanExecutionStatus, { testPlanExecutionHistory: row.original }),
            },
            {
                header: translate('Build version'),
                cell: ({ row }) => {
                    return (_jsx(Tooltip, { title: row.original.metadata.sandboxName, children: row.original.metadata.sandboxVersion || EM_DASH }));
                },
            },
            {
                header: translate('Executed on'),
                cell: ({ row }) => {
                    return _jsx(FormattedDateTime, { date: row.original.createdAt });
                },
            },
            {
                header: translate('Execution time'),
                cell: ({ row }) => {
                    if (row.original.status === 'RUNNING') {
                        return EM_DASH;
                    }
                    const startedAt = new Date(row.original.createdAt);
                    const endedAt = new Date(row.original.updatedAt);
                    const duration = endedAt.getTime() - startedAt.getTime();
                    const seconds = Math.floor(duration / 1000);
                    const minutes = Math.floor(seconds / 60);
                    const hours = Math.floor(minutes / 60);
                    if (hours > 0) {
                        return `${hours}h ${minutes % 60}m`;
                    }
                    else if (minutes > 0) {
                        return `${minutes}m ${seconds % 60}s`;
                    }
                    return `${seconds}s`;
                },
            },
            {
                header: translate('Report'),
                cell: ({ row }) => {
                    if (row.original.blobPath) {
                        return (_jsx(Link, { LinkComponent: RouterLink, to: row.original.blobPath, target: '_blank', onClick: async (e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                const url = await fetchReportUrl(row.original.id);
                                window.open(url, '_blank');
                            }, children: translate('View report') }));
                    }
                    return EM_DASH;
                },
            },
            {
                header: '',
                id: 'options',
                cell: ({ row }) => {
                    const dropdownOptions = [];
                    if (row.original.status === 'RUNNING') {
                        return null;
                    }
                    dropdownOptions.push({
                        id: 'delete',
                        content: translate('Delete'),
                        onClick: () => {
                            deleteExecutionHistory(row.original.id);
                        },
                        destructive: true,
                    });
                    return (_jsx("section", { className: 'flex flex-col items-end', children: _jsx(Dropdown, { position: 'bottom-end', items: dropdownOptions, variant: 'kebab' }) }));
                },
            },
        ];
    }, []);
    return (_jsxs("section", { className: 'relative h-full flex flex-col', children: [_jsx(PageHeader, { title: testPlan?.name || '', customOptions: _jsxs("div", { className: 'flex gap-4 items-center', children: [_jsx(Button, { label: translate('View test plan'), onClick: showTestPlan, variant: 'secondary' }), _jsx(Button, { label: translate('Execute'), onClick: openExecuteTestPlanModal, variant: 'primary' }), _jsx(Dropdown, { items: [
                                {
                                    id: 'clone',
                                    content: translate('Clone'),
                                    onClick: () => {
                                        setIsEditModalOpen('clone');
                                    },
                                },
                                {
                                    id: 'edit',
                                    content: translate('Edit'),
                                    onClick: () => {
                                        setIsEditModalOpen('edit');
                                    },
                                },
                                {
                                    id: 'delete',
                                    content: translate('Delete'),
                                    onClick: () => {
                                        onDelete();
                                    },
                                    destructive: true,
                                },
                            ], variant: 'kebab' })] }) }), _jsxs("div", { className: 'flex-1 overflow-auto relative', children: [_jsx(ListInformation, { items: listItems }), _jsxs("div", { className: 'mt-8', children: [_jsx("h2", { className: 'text-lg font-semibold text-gray-800 mb-3', children: translate('Execution history') }), _jsx(Table, { columns: executionHistoryColumns, data: testPlanHistoryList, hasPagination: true, initialPageSize: 10, isLoading: isLoading, paginationNavigationScreenReaderLabel: translate('Test plan table navigation') })] }), isExecuteTestPlanModalOpen ? (_jsx(ExecuteTestPlanModal, { testPlanId: testPlan.id, onClose: closeExecuteTestPlanModal, onSave: closeExecuteTestPlanModal, tests: studyTests })) : null, isEditModalOpen ? (_jsx(EditTestPlanDetailsModal, { onClose: onCloseEditModal, onSave: onSaveEditModal, initialTestPlan: testPlan, type: isEditModalOpen })) : null] })] }));
};
