import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useContext, useMemo, useState } from 'react';
import { Checkbox, FormSections, useLocalizeMessage, Input, ModalWindow, InlineNotification, RadioGroup, RadioGroupOption, TextArea, } from 'libs.nucleus';
import { SELF_SERVICE_INFO_TYPES } from '../../../constants';
import { StudyConfigurationContext } from '../../../contexts';
export const StudyParticipantDetailsEditModal = ({ initialMetadata, onCancel, onSave, }) => {
    const { currentStudy, isLoading } = useContext(StudyConfigurationContext);
    const translate = useLocalizeMessage();
    const [isPristine, setIsPristine] = useState(true);
    const [studyMetadata, setStudyMetadata] = useState(initialMetadata);
    const [participantIdGenerationFormat, setParticipantIdGenerationFormat] = useState(currentStudy.subjectIdAsATask ? 'OnSite' : 'Integration');
    const isEditEnabled = !isLoading && !isPristine;
    const onParticipantNumberFormatChange = useCallback((event) => {
        setStudyMetadata((prevMetadata) => ({ ...prevMetadata, subjectIdFormat: event.target.value }));
        setIsPristine(false);
    }, []);
    const onSupportHeaderChange = useCallback((event) => {
        setStudyMetadata((prevMetadata) => ({
            ...prevMetadata,
            selfServiceInformation: {
                ...prevMetadata.selfServiceInformation,
                [SELF_SERVICE_INFO_TYPES.SUPPORT]: event.target.value,
            },
        }));
        setIsPristine(false);
    }, []);
    const onIsNoPIIEnabledChange = useCallback((isNoPIIEnabled) => {
        setStudyMetadata((prevMetadata) => ({ ...prevMetadata, isNoPIIEnabled }));
        setIsPristine(false);
    }, []);
    const onAuthenticationNameChange = useCallback((useName) => {
        setStudyMetadata((prevMetadata) => ({
            ...prevMetadata,
            authentication: {
                ...prevMetadata.authentication,
                firstName: useName,
                lastName: useName,
            },
        }));
        setIsPristine(false);
    }, []);
    const onAuthenticationPhoneChange = useCallback((usePhoneNumber) => {
        setStudyMetadata((prevMetadata) => ({
            ...prevMetadata,
            authentication: {
                ...prevMetadata.authentication,
                phoneNumber: usePhoneNumber,
            },
        }));
        setIsPristine(false);
    }, []);
    const onParticipantIdGenerationFormatChange = (value) => {
        setParticipantIdGenerationFormat(value);
        setStudyMetadata((prevMetadata) => ({
            ...prevMetadata,
            subjectIdGeneration: 'Manual',
            subjectIdAsATask: value === 'OnSite',
        }));
        setIsPristine(false);
    };
    const savePrimaryButton = {
        label: translate('Save'),
        onClick: () => onSave(studyMetadata),
        disabled: !isEditEnabled,
    };
    const cancelSecondaryButton = {
        label: translate('Cancel'),
        onClick: onCancel,
    };
    const handleSave = (event) => {
        event.preventDefault();
        if (isEditEnabled) {
            onSave(studyMetadata);
        }
    };
    const handleEnterPress = (event) => {
        if (event.key === 'Enter' && isEditEnabled) {
            onSave(studyMetadata);
        }
    };
    const formSections = useMemo(() => {
        const registrationMethods = [
            {
                value: false,
                label: translate('Email'),
                description: translate('Participants will register and sign with their email address and be able to reset password on their own'),
            },
            {
                value: true,
                label: translate('Username'),
                description: translate('Participants will register and sign in with a username. Sites will provide app invite codes directly (not via email) and if participants lose their password sites will provide temporary passwords directly(not via email)'),
                disabled: currentStudy.isEConsentEnabled,
            },
        ];
        const sections = [
            {
                title: translate('Participant details'),
                content: (_jsxs("section", { className: 'flex flex-col gap-6 max-w-input-lg', children: [_jsxs(RadioGroup, { label: translate('Participant ID creation'), value: participantIdGenerationFormat, onChange: onParticipantIdGenerationFormatChange, children: [_jsx(RadioGroupOption, { option: {
                                        value: 'OnSite',
                                        label: translate('Site input'),
                                        description: translate('An activity will be automatically added to the schedule where site users will be able to manually enter the participant ID'),
                                    } }), _jsx(RadioGroupOption, { option: {
                                        value: 'Integration',
                                        label: translate('Integration'),
                                        description: translate('Participant IDs will be created via a 3rd party integration'),
                                    } })] }), _jsx(Input, { id: 'edit-participant-number-format-input', label: translate('Participant number format'), description: translate('Enter the number format you would like participant IDs to display in. Use dynamic tag {sitePattern} to auto populate site numbers as part of the participant ID.', { sitePattern: '{SITE}' }), onChange: onParticipantNumberFormatChange, value: studyMetadata.subjectIdFormat, placeholder: '{SITE}-###', infoMessage: translate('For example, typing {sitePattern}-### could generate a participant ID like S001-001, depending on your site numbers.', { sitePattern: '{SITE}' }), required: true, containLabels: true, width: 'full' }), _jsx(RadioGroup, { label: translate('Participant registration method'), value: studyMetadata.isNoPIIEnabled, onChange: onIsNoPIIEnabledChange, required: true, children: registrationMethods.map((option) => (_jsx(RadioGroupOption, { option: option, disabled: option.disabled }, option.label))) }), currentStudy.isEConsentEnabled && (_jsx(InlineNotification, { type: 'warning', role: 'status', title: translate('Warning'), subtitle: translate('Your study has eConsent enabled. to change the registration method to "Username", please disable eConsent first') }))] })),
            },
        ];
        if (studyMetadata.isNoPIIEnabled) {
            sections.push({
                title: translate('Support information'),
                description: translate('Enabled for participants in the app when Username is selected for registration method above.'),
                content: (_jsx("section", { className: 'max-w-input-lg', children: _jsx(TextArea, { id: 'edit-support-header-textarea', label: translate('Description'), description: translate('e.g. Please contact your study site team to retrieve your username or reset password'), onChange: onSupportHeaderChange, value: studyMetadata.selfServiceInformation[SELF_SERVICE_INFO_TYPES.SUPPORT], "aria-label": translate('Edit Support Information Textarea'), disabled: !studyMetadata.isNoPIIEnabled, width: 'full' }) })),
            });
        }
        else {
            sections.push({
                title: translate('Account creation information'),
                description: translate('Choose the fields that are available when a participant creates their account.'),
                content: (_jsxs("section", { className: 'flex flex-col gap-4', children: [_jsx(Checkbox, { label: translate('First and last name'), checked: studyMetadata.authentication?.firstName || false, onChange: onAuthenticationNameChange }), _jsx(Checkbox, { label: translate('Phone number'), checked: studyMetadata.authentication?.phoneNumber || false, onChange: onAuthenticationPhoneChange })] })),
            });
        }
        return sections;
    }, [studyMetadata]);
    return (_jsx(ModalWindow, { title: translate('Edit participant details'), subtitle: currentStudy.name, isOpen: true, closeWindow: onCancel, footerPrimaryActionButton: savePrimaryButton, footerSecondaryActionButtons: [cancelSecondaryButton], width: 'full', children: _jsx("form", { onSubmit: handleSave, autoComplete: 'off', onKeyUp: handleEnterPress, children: _jsx(FormSections, { sections: formSections }) }) }));
};
