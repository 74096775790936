import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { JobTypes } from 'libs.jobs.common';
import { useCreateJob } from 'libs.jobs.react';
import { ModalWindow, useLocalizeMessage } from 'libs.nucleus';
import { AssetLocalePairSelection } from './asset_locale_pair_selection.component';
export const GenTranslationMultiSelectionModal = ({ hideLocales = [], assets, locales, onCancel = () => { }, onSubmit = () => { }, }) => {
    const t = useLocalizeMessage();
    const [assetLocalePair, setAssetLocalePair] = useState([]);
    const filteredLocales = locales.filter((locale) => !hideLocales.includes(locale));
    const createJob = useCreateJob();
    const onDoneClick = () => {
        if (assetLocalePair.length === 0) {
            return;
        }
        for (const { locale, asset_id } of assetLocalePair) {
            createJob(JobTypes.TRANSLATION__GENERATE_TRANSLATION, { locale, asset_id });
        }
        onSubmit();
    };
    const doneButton = {
        label: t('Translate'),
        onClick: onDoneClick,
        disabled: assetLocalePair.length === 0,
    };
    const closeButton = {
        label: t('Cancel'),
        onClick: onCancel,
    };
    return (_jsx(ModalWindow, { title: t('Translate with AI'), isOpen: true, closeWindow: onCancel, footerSecondaryActionButtons: [closeButton], footerPrimaryActionButton: doneButton, width: 'lg', position: 'top', children: _jsxs("section", { className: 'w-full overflow-x-hidden font-sans flex flex-col gap-7 py-8 first:pt-0 last:pb-0 md:flex-row md:gap-8 border-solid border-neutral-200 border-x-0 border-t-0 border-b last:border-b-0', children: [_jsx("div", { className: 'flex shrink-0 flex-col gap-2 md:w-48', children: _jsx("h2", { className: 'font-sans text-h5 font-semibold m-0 box-border text-text-primary', children: t('Assets') }) }), _jsx("div", { className: 'w-full overflow-x-auto', children: _jsx(AssetLocalePairSelection, { assets: assets, locales: filteredLocales, onSelectionChange: setAssetLocalePair }) })] }) }));
};
