"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useAnswers = void 0;
const react_1 = require("react");
/**
 * Custom hook for managing an instrument's answers.
 *
 * @param currentStepId Id of the current step.
 * @param api API object for tracking step events. Using as a parameter for now until we have a real singleton API
 *     object.
 */
const useAnswers = (currentStepId, api) => {
    const [answers, setAnswers] = (0, react_1.useState)({});
    function updateAnswer(answer) {
        var _a, _b, _c;
        if (((_a = answers[currentStepId]) === null || _a === void 0 ? void 0 : _a.value) === (answer === null || answer === void 0 ? void 0 : answer.value) ||
            // Since two NaN values are never equal, we need to check if both are NaN to avoid an infinite loop.
            (typeof ((_b = answers[currentStepId]) === null || _b === void 0 ? void 0 : _b.value) === 'number' &&
                isNaN((_c = answers[currentStepId]) === null || _c === void 0 ? void 0 : _c.value) &&
                typeof (answer === null || answer === void 0 ? void 0 : answer.value) === 'number' &&
                isNaN(answer === null || answer === void 0 ? void 0 : answer.value))) {
            // Don't update if the value is the same, or else the step component will re-render infinitely.
            return;
        }
        api.trackStepEvent('update', currentStepId);
        setAnswers(Object.assign(Object.assign({}, answers), { [currentStepId]: answer }));
    }
    return {
        /**
         * A map with the latest answer recorded for each step.
         */
        answers,
        /**
         * The answer of the current step.
         */
        currentAnswer: answers[currentStepId],
        /**
         * A function to update the answer for the current step.
         */
        updateAnswer,
    };
};
exports.useAnswers = useAnswers;
