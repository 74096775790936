import { useQuery, keepPreviousData, useMutation, QueryClientContext } from '@tanstack/react-query';
import querystring from 'query-string';
import { useContext, useEffect, useState } from 'react';
import { ApiClientService, AuthContext, useApiClient } from 'libs.react';
// todo: add search functionality
export const useFetchWorkspaceSites = ({ pageIndex = 0, pageSize = 10 }) => {
    const { entityId } = useContext(AuthContext);
    const momClient = useApiClient(ApiClientService.MOM);
    const [error, setError] = useState();
    const [pagination, setPagination] = useState({ pageSize, pageIndex });
    const [totalPages, setTotalPages] = useState(0);
    // const [search, setSearch] = useState('');
    const querySubKey = `${pagination.pageIndex.toString()}/${pagination.pageSize.toString()}`;
    const queryKey = ['workspaceSites', querySubKey];
    // const debouncedSearchChange = useCallback(
    //   debounce((value = '') => {
    //     setSearch(value as string);
    //   }, DEFAULT_DEBOUNCE_TIME),
    //   []
    // );
    const fetchSites = async () => {
        try {
            const queryObject = {
                limit: pagination.pageSize.toString(),
                page: (pagination.pageIndex + 1).toString(),
                // ...(search && { search }),
            };
            const queryParams = querystring.stringify(queryObject);
            const { data: response } = await momClient.get(`/v1/entities/${entityId}/sites?${queryParams}`);
            const totalPages = Math.ceil(response.total / pagination.pageSize);
            setTotalPages(totalPages);
            return response?.data || [];
        }
        catch (error) {
            setError(error);
        }
    };
    const { data: workspaceSites, isLoading } = useQuery({
        queryKey,
        queryFn: fetchSites,
        placeholderData: keepPreviousData,
    });
    useEffect(() => {
        fetchSites();
    }, [pagination.pageIndex, pagination.pageSize]);
    return {
        isLoading,
        error,
        workspaceSites,
        pagination,
        totalPages,
        onPaginationChange: setPagination,
        // onSearchChange: debouncedSearchChange,
    };
};
export const useFetchWorkspaceSiteById = (workspaceSiteId = '') => {
    const { entityId } = useContext(AuthContext);
    const momClient = useApiClient(ApiClientService.MOM);
    const fetchSite = async () => {
        const { data: response } = await momClient.get(`/v1/entities/${entityId}/sites/${workspaceSiteId}`);
        return response?.data;
    };
    const { data: workspaceSite, isLoading } = useQuery({
        queryKey: ['workspaceSites', workspaceSiteId],
        queryFn: fetchSite,
    });
    return {
        isLoading,
        workspaceSite,
    };
};
export const useMutateWorkspaceSiteById = (workspaceSiteId = '') => {
    const { entityId } = useContext(AuthContext);
    const momClient = useApiClient(ApiClientService.MOM);
    const queryClient = useContext(QueryClientContext);
    const workspaceSiteMutation = useMutation({
        mutationFn: (data) => {
            if (workspaceSiteId) {
                return momClient.put(`/v1/entities/${entityId}/sites/${workspaceSiteId}`, data);
            }
            return momClient.post(`/v1/entities/${entityId}/sites`, data);
        },
        onSuccess: async () => {
            if (workspaceSiteId) {
                await queryClient?.invalidateQueries({ queryKey: ['workspaceSites', workspaceSiteId.toString()] });
            }
            else {
                await queryClient?.invalidateQueries({ queryKey: ['workspaceSites'] });
            }
        },
    });
    return {
        isPending: workspaceSiteMutation.isPending,
        mutateAsync: workspaceSiteMutation.mutateAsync,
        isError: workspaceSiteMutation.isError,
        error: workspaceSiteMutation.error,
    };
};
