import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext, useMemo, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { FormSections, useLocalizeMessage, Input, ModalWindow, Spinner, TextArea } from 'libs.nucleus';
import { useApiClient, ApiClientService, useToastNotification, LibraryEndpoint } from 'libs.react';
import { StudyConfigurationContext } from '../../contexts';
export const EditTestPlanDetailsModal = ({ onClose, onSave, initialTestPlan, type }) => {
    const translate = useLocalizeMessage();
    const { addNotification } = useToastNotification();
    const libClient = useApiClient(ApiClientService.LIBRARY);
    const { studyId } = useContext(StudyConfigurationContext);
    const [isLoading, setIsLoading] = useState(false);
    const [studyTestForm, setStudyTestForm] = useState({
        name: type === 'edit' ? initialTestPlan.metadata.name : '',
        description: type === 'edit' ? initialTestPlan.data.description : '',
    });
    const saveDisabled = useMemo(() => {
        return !studyTestForm.name || isLoading;
    }, [studyTestForm, isLoading]);
    const saveTest = async () => {
        if (!saveDisabled) {
            try {
                setIsLoading(true);
                let newConfig;
                if (type === 'clone') {
                    const { data: response } = await libClient.post(`${LibraryEndpoint.GET_TEST_PLANS}`, {
                        name: studyTestForm.name,
                        key: uuidv4(),
                        data: {
                            description: studyTestForm.description,
                            csv: initialTestPlan.data.csv,
                        },
                        metadata: {
                            name: studyTestForm.name,
                            sandboxId: initialTestPlan.metadata.sandboxId,
                            sandboxName: initialTestPlan.metadata.sandboxName,
                            sandboxResourceId: initialTestPlan.metadata.sandboxResourceId,
                        },
                        studyId,
                    });
                    newConfig = response.data;
                }
                else {
                    const { data: response } = await libClient.put(`${LibraryEndpoint.GET_TEST_PLANS}/${initialTestPlan.id}`, {
                        name: studyTestForm.name,
                        data: {
                            description: studyTestForm.description,
                            csv: initialTestPlan.data.csv,
                        },
                        metadata: {
                            name: studyTestForm.name,
                            sandboxId: initialTestPlan.metadata.sandboxId,
                            sandboxName: initialTestPlan.metadata.sandboxName,
                            sandboxResourceId: initialTestPlan.metadata.sandboxResourceId,
                        },
                    });
                    newConfig = response.data;
                }
                onSave(newConfig);
            }
            catch (error) {
                console.error(`Error in ${type} study`, error);
                onDeployError();
            }
        }
    };
    const onDeployError = () => {
        addNotification({ title: translate('Error "type"ing study', { type }), type: 'error' });
        setIsLoading(false);
    };
    const savePrimaryButton = {
        label: translate('Save'),
        onClick: saveTest,
        disabled: saveDisabled,
    };
    const cancelSecondaryButton = {
        label: translate('Cancel'),
        onClick: onClose,
    };
    const onSubmit = (event) => {
        event.preventDefault();
        saveTest();
    };
    const handleInputChange = (event) => {
        const { id, value } = event.target;
        setStudyTestForm((oldForm) => ({ ...oldForm, [id]: value }));
    };
    const formSections = [
        {
            title: translate('Test plan details'),
            content: (_jsxs("div", { className: 'flex flex-col gap-6', children: [_jsx(Input, { id: 'name', label: translate('Name'), maxLength: 50, onChange: handleInputChange, required: true, value: studyTestForm.name, width: 'lg', autoComplete: 'off' }), _jsx(TextArea, { id: 'description', label: translate('Description'), onChange: handleInputChange, value: studyTestForm.description, width: 'lg' })] })),
        },
    ];
    return (_jsxs(ModalWindow, { title: type === 'clone' ? translate('Clone test plan') : translate('Edit test plan'), isOpen: true, closeWindow: onClose, footerPrimaryActionButton: savePrimaryButton, footerSecondaryActionButtons: [cancelSecondaryButton], width: 'lg', children: [isLoading && _jsx(Spinner, { wrapper: 'full' }), _jsx("form", { className: 'min-h-[30rem]', autoComplete: 'off', onSubmit: onSubmit, children: _jsx(FormSections, { sections: formSections }) })] }));
};
