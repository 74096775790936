import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Spinner, H2 } from 'libs.nucleus';
import { AuthContext } from '../../features';
import { FullLogo } from '../full_logo';
/**
 * This is a generic component that wraps the auth forms
 * it is the intention to use this wrapper to construct any authentication page like login, forgot password, etc.
 */
export const AuthWrapper = ({ children, error, onSubmit, title, }) => {
    const [searchParams] = useSearchParams();
    const { isLoading, isLoggedIn } = useContext(AuthContext);
    const navigate = useNavigate();
    useEffect(() => {
        if (!isLoading && isLoggedIn && searchParams.get('expired-session') !== 'true') {
            navigate(searchParams.get('redirect') || '/');
        }
    }, [isLoggedIn, isLoading]);
    const handleOnSubmit = (event) => {
        event.preventDefault();
        onSubmit && onSubmit();
    };
    return (_jsxs("form", { className: 'flex flex-col text-center items-center justify-center h-screen gap-6 bg-neutral-75 p-2', onSubmit: handleOnSubmit, children: [_jsx(FullLogo, {}), title && _jsx(H2, { children: title }), _jsxs("section", { className: 'relative flex flex-col rounded-lg bg-white p-6 max-w-[30rem] w-full', children: [isLoading && (_jsx("section", { "data-testid": 'auth-wrapper-loading', className: 'absolute inset-0 z-10 flex items-center justify-center bg-white/60', children: _jsx(Spinner, {}) })), error && _jsx("div", { className: 'border border-red-500 rounded-md p-3 mb-2', children: error }), children] })] }));
};
