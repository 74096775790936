import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useAtom } from 'jotai';
import { useEffect } from 'react';
import DeviceTopBar from './device_topbar';
import { deviceZoomLevelAtom, selectedDeviceAtom } from '../device_selector_store';
import { DevicePreviewContainer } from '../styles';
export const DevicePreview = ({ src, srcDoc, children, disableInteractions = false, showTopbar = true, }) => {
    const [device] = useAtom(selectedDeviceAtom);
    const [zoomLevel, setZoomLevel] = useAtom(deviceZoomLevelAtom);
    const { width, height } = device;
    let viewportHeight = 'h-full';
    if (showTopbar) {
        viewportHeight = 'h-[calc(100%-20px)]';
    }
    useEffect(() => {
        setZoomLevel(device.scale);
    }, []);
    return (_jsx(DevicePreviewContainer, { style: {
            width: `${width * zoomLevel}px`,
            height: `${height * zoomLevel}px`,
        }, "data-testid": 'lib_device_preview', children: _jsxs("div", { className: 'border-4 rounded-2xl border-neutral-300 transition-all duration-300 ease-in-out origin-top-left', style: { width: `${width}px`, height: `${height}px`, transform: `scale(${zoomLevel})` }, children: [showTopbar && _jsx(DeviceTopBar, {}), _jsxs("div", { className: `w-full ${viewportHeight} overflow-auto ${disableInteractions ? '[&>*]:pointer-events-none' : ''}`, children: [(src || srcDoc) && _jsx("iframe", { src: src, srcDoc: srcDoc, title: 'Tasks', width: '100%', height: '100%' }), (!src || !srcDoc) && children] })] }) }));
};
