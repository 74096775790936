import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useLocalizeMessage, H5 } from 'libs.nucleus';
import { ListInformation } from 'libs.react';
export const WorkspaceSiteDetails = ({ workspaceSite }) => {
    const translate = useLocalizeMessage();
    const fullAddressLine = `${workspaceSite.address.city}, ${workspaceSite.address.region}, ${workspaceSite.address.countryCode}${workspaceSite.address.postalCode ? `, ${workspaceSite.address.postalCode}` : ''}`;
    const basicInformation = [
        {
            title: translate('Name'),
            description: workspaceSite.name,
        },
        {
            title: translate('Site library ID'),
            description: workspaceSite.number,
        },
        {
            title: 'Timezone',
            description: workspaceSite.timezone,
        },
        {
            title: 'Locale',
            description: workspaceSite.siteLocales?.join(', '),
        },
    ];
    const contactInformation = [
        {
            title: 'Email',
            description: workspaceSite.email,
        },
        {
            title: 'Phone',
            description: workspaceSite.address.phone.map((phone) => `${phone.type}: ${phone.number}`).join(', '),
        },
        {
            title: 'Country',
            description: workspaceSite.address.countryCode,
        },
        {
            title: 'Address',
            description: fullAddressLine,
        },
    ];
    return (_jsxs(_Fragment, { children: [_jsx(ListInformation, { items: basicInformation }), _jsx(H5, { className: 'mb-6 mt-6', children: translate('Contact information') }), _jsx(ListInformation, { items: contactInformation })] }));
};
