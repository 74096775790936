import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import classNames from 'classnames';
import React, { useRef, useEffect, useMemo, useCallback } from 'react';
import SpreadsheetStyles from './spreadsheet_styles';
import { useSpreadsheetDispatch, useSpreadsheetSelector } from '../../contexts/spreadsheet';
import { GenericUtils } from '../../utils';
const SelectDataEditor = ({ cell, onChange, exitEditMode, getOptions, row, column, clearOtherCells, }) => {
    const { clearOtherCells: clearCellsInRow, showSelect } = useSpreadsheetDispatch();
    const clearCells = useCallback(() => {
        clearCellsInRow({ row, column });
    }, [clearCellsInRow, row, column]);
    const rowCells = useSpreadsheetSelector((state) => state.data[row]);
    const options = useMemo(() => getOptions(rowCells), [rowCells, getOptions]);
    const showSelectPopup = React.useCallback((position) => showSelect({
        position,
        value: cell?.value || '',
        onChange: (value) => {
            onChange({
                ...cell,
                value,
            });
            if (clearOtherCells) {
                clearCells();
            }
            exitEditMode();
        },
        options,
    }), [showSelect, cell, onChange, exitEditMode, options]);
    const viewerRef = useRef(null);
    useEffect(() => {
        if (viewerRef.current) {
            const rect = viewerRef.current.getBoundingClientRect();
            showSelectPopup(rect);
        }
    }, []);
    const option = useMemo(() => options.find((option) => GenericUtils.isSameString(option.value, cell?.value)), [options, cell?.value]);
    return (_jsx(_Fragment, { children: _jsx("span", { ref: viewerRef, className: classNames(SpreadsheetStyles.dataViewer, SpreadsheetStyles.dataViewerFixedWidth), children: option?.label }) }));
};
export default SelectDataEditor;
