import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, useLocalizeMessage } from 'libs.nucleus';
import { getOnboardingConfig } from '../../helpers/study.helpers';
import { useOrgContext } from '../../hooks/use_org_context';
import { Card } from '../card';
export const OnboardingSchedule = ({ onboardingScheduleActivities, setCurrentVisit, setIsActivityListModalOpen, }) => {
    const translate = useLocalizeMessage();
    const org = useOrgContext();
    const onboardingGroupId = getOnboardingConfig(org)?.c_group_id;
    return (_jsx(Card, { children: _jsxs("div", { className: 'flex flex-col gap-4', children: [_jsx("div", { className: 'flex gap-4', children: translate('Onboarding') }), _jsxs("div", { className: 'flex gap-2 items-center', children: [_jsx("span", { className: 'text-gray-500', "data-testid": 'onboarding-assessments', children: translate('{assignments} assessments', { assignments: onboardingScheduleActivities.length }) }), _jsx("span", { className: 'ml-auto', children: _jsx(Button, { label: translate('Start onboarding'), onClick: () => {
                                    setCurrentVisit({
                                        c_visit: '',
                                        c_name: translate('Onboarding'),
                                        c_group: onboardingGroupId ?? '',
                                        c_window_start: Date.now(),
                                        c_window_end: Date.now(),
                                        c_open_queries: 0,
                                        c_completed_assignments: 0,
                                        c_completed_assignments_for_site: 0,
                                        c_sequence: 0,
                                        assignments: onboardingScheduleActivities.length,
                                    });
                                    setIsActivityListModalOpen();
                                } }) })] })] }) }));
};
