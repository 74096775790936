"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/** Defines stubs for APIs for testing */
if (global.submit === undefined) {
    global.submit = () => { };
}
if (global.track === undefined) {
    console.log('track stubbed');
    global.track = () => { };
}
if (global.cancel === undefined) {
    global.cancel = () => { };
}
if (global.callApi === undefined) {
    global.callApi = () => Promise.resolve({});
}
/** Api to communicate with the client. */
class Api {
    constructor() {
        this.events = [];
        this.trackTaskEvent('loaded');
        window.onerror = (error) => {
            this.trackEvent({ name: 'error', detail: error.toString(), timestamp: new Date() });
        };
    }
    cancel(reason = 'user') {
        this.trackEvent({ name: 'cancel', timestamp: new Date(), reason });
        cancel();
    }
    submit(responses) {
        this.trackTaskEvent('completion');
        submit({ responses, events: this.events });
    }
    trackStepEvent(event, stepId) {
        this.trackEvent({ name: event, stepId, timestamp: new Date() });
    }
    trackTaskEvent(event) {
        this.trackEvent({ name: event, timestamp: new Date() });
    }
    trackEvent(event) {
        this.events.push(event);
        track(event);
    }
    callApi(path, options) {
        return callApi(path, options);
    }
}
exports.default = Api;
