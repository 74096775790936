import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { v4 } from 'uuid';
import { DefaultActivityId, ResponderRole } from '@axon/rosetta-sdk';
import { Button, Checkbox, FormSections, useLocalizeMessage, Icon, Input, InlineNotification, Select, TimePicker, Text, } from 'libs.nucleus';
import { DISABLED_ENDING_MAP, DISABLED_RECURRENCE_MAP, DISABLED_STARTING_MAP } from './activity_dependencies.constants';
import { StartingConditionType, EndingConditionType, } from './activity_dependency.types';
import { GenericUtils } from '../../../../utils';
import { SelectActivityResponse, } from '../activity_instance_edit_modal/select_activity_response.component';
const MAX_REPETITION_EVERY = 500;
const MAX_DURATION = 100;
const filterArr = [
    EndingConditionType.ACTIVITY_RESPONSE,
    EndingConditionType.VISIT_CONFIRMATION,
    EndingConditionType.AFTER_ACTIVITY_COMPLETION,
    EndingConditionType.ENDLESS,
    EndingConditionType.NUM_OF_OCURRENCES,
];
export const ActivityDependencies = ({ onPristineChange, activityDependencyUtils, isRemoteActivity, activity, remoteType, showSpecificStartTime, onShowSpecificStartTimeChange, visit, }) => {
    const translate = useLocalizeMessage();
    const { startingConditionOptions, endingConditionOptions, onboardingVisit, visitConfirmationOption, startingConditions, endingConditions, activityCompletionOptions, dayOptions, periodOptions, vrsResponseOptions, dateTimeResponseOptions, setStartingConditions, setEndingConditions, allVisitOptions, specificStartTime, setSpecificStartTime, repetition, setRepetition, recurrenceEndingOptions, } = activityDependencyUtils;
    const repetitionOptions = periodOptions.filter((option) => option.value !== 'hours');
    const getDefaultActivityResponse = (options) => {
        return {
            activity: options.activities[0],
            screen: options.screenOptions[''].screens[0],
            response: options.screenOptions[''].responses[''][0],
        };
    };
    const replaceEodAndAfterDurationConditions = () => {
        const newEndingConditions = filteredEndingConditions.filter((condition) => condition.type !== EndingConditionType.END_OF_DAY && condition.type !== EndingConditionType.AFTER_DURATION);
        setEndingConditions(newEndingConditions.length === 0
            ? [{ id: v4(), option: endingConditionOptions.uponCompletion, type: EndingConditionType.UPON_COMPLETION }]
            : newEndingConditions);
    };
    const filteredEndingConditions = remoteType !== 'recurring' ? endingConditions : endingConditions.filter((c) => !filterArr.includes(c.type));
    const handleStartingSelectChange = (conditionIndex, id, option) => {
        onPristineChange && onPristineChange(false);
        setStartingConditions((conditions) => {
            if (id === 'activityCompletion' &&
                typeof option === 'object' &&
                'value' in option &&
                (option.value ===
                    onboardingVisit.getActivitySequenceByActivityId(DefaultActivityId.AUTHENTICATION)?.instanceId ||
                    option.value ===
                        onboardingVisit.getActivitySequenceByActivityId(DefaultActivityId.ENTER_PARTICIPANT_NUMBER)?.instanceId)) {
                // @ts-expect-error - This is a valid assignment
                conditions[conditionIndex] = {
                    ...conditions[conditionIndex],
                    [id]: option,
                    offset: dayOptions[0],
                };
            }
            else {
                conditions[conditionIndex] = { ...conditions[conditionIndex], [id]: option };
            }
            return [...conditions];
        });
    };
    const handleStartingConditionChange = (conditionIndex, option) => {
        if (startingConditions[conditionIndex].option.value === option.value) {
            return;
        }
        handleStartingSelectChange(conditionIndex, 'option', option);
        handleStartingSelectChange(conditionIndex, 'type', option.value);
        if (option.value === StartingConditionType.VISIT_CONFIRMATION) {
            handleStartingSelectChange(conditionIndex, 'activityCompletion', visitConfirmationOption);
        }
        if (option.value === StartingConditionType.AFTER_ACTIVITY_COMPLETION) {
            handleStartingSelectChange(conditionIndex, 'activityCompletion', activityCompletionOptions[0]);
        }
        if (option.value === StartingConditionType.ACTIVITY_RESPONSE ||
            option.value === StartingConditionType.DATE_RESPONSE) {
            const options = option.value === StartingConditionType.ACTIVITY_RESPONSE ? vrsResponseOptions : dateTimeResponseOptions;
            handleStartingSelectChange(conditionIndex, 'activityResponse', getDefaultActivityResponse(options));
        }
        if (option.value === StartingConditionType.ACTIVITY_RESPONSE) {
            handleStartingSelectChange(conditionIndex, 'offset', dayOptions[0]);
        }
        // We validate the ending conditions based on the selected starting conditions
        if ((activity?.responderRole === ResponderRole.SITE && option.value === StartingConditionType.STUDY_CREATION) ||
            (remoteType !== 'recurring' &&
                startingConditions.length === 1 &&
                option.value !== StartingConditionType.VISIT_CONFIRMATION &&
                option.value !== StartingConditionType.DATE_RESPONSE)) {
            replaceEodAndAfterDurationConditions();
        }
        if (remoteType === 'recurring' &&
            (option.value === StartingConditionType.AFTER_ACTIVITY_COMPLETION ||
                option.value === StartingConditionType.ACTIVITY_RESPONSE) &&
            endingConditions[0].type === EndingConditionType.END_OF_DAY) {
            setEndingConditions([
                {
                    id: v4(),
                    option: endingConditionOptions.afterDuration,
                    type: EndingConditionType.AFTER_DURATION,
                    duration: '1',
                },
            ]);
        }
    };
    const handleEndingSelectChange = (conditionIndex, id, option) => {
        onPristineChange(false);
        setEndingConditions((conditions) => {
            conditions[conditionIndex] = { ...conditions[conditionIndex], [id]: option };
            return [...conditions];
        });
    };
    const handleEndingConditionChange = (conditionIndex, option) => {
        if (endingConditions[conditionIndex].option.value === option.value) {
            return;
        }
        handleEndingSelectChange(conditionIndex, 'option', option);
        handleEndingSelectChange(conditionIndex, 'type', option.value);
        if (option.value === EndingConditionType.VISIT_CONFIRMATION) {
            handleEndingSelectChange(conditionIndex, 'activityCompletion', visitConfirmationOption);
        }
        if (option.value === EndingConditionType.AFTER_DURATION) {
            handleEndingSelectChange(conditionIndex, 'duration', '1');
            handleEndingSelectChange(conditionIndex, 'durationPeriod', periodOptions[1]);
        }
        if (option.value === EndingConditionType.AFTER_ACTIVITY_COMPLETION) {
            handleEndingSelectChange(conditionIndex, 'activityCompletion', activityCompletionOptions[0]);
        }
        if (option.value === EndingConditionType.ACTIVITY_RESPONSE || option.value === EndingConditionType.DATE_RESPONSE) {
            const options = option.value === EndingConditionType.ACTIVITY_RESPONSE ? vrsResponseOptions : dateTimeResponseOptions;
            handleEndingSelectChange(conditionIndex, 'activityResponse', getDefaultActivityResponse(options));
        }
        if (option.value === EndingConditionType.ACTIVITY_RESPONSE ||
            option.value === EndingConditionType.END_OF_DAY ||
            option.value === EndingConditionType.AFTER_DURATION ||
            option.value === EndingConditionType.UPON_COMPLETION) {
            handleEndingSelectChange(conditionIndex, 'offset', dayOptions[0]);
        }
    };
    const getValidStartingConditions = (condition) => {
        const isHiddenByCondition = (key) => startingConditions.length > 1 && DISABLED_STARTING_MAP[condition.option.value][key];
        return [
            {
                ...startingConditionOptions.visitConfirmation,
                hidden: (!isRemoteActivity && !visitConfirmationOption) || isHiddenByCondition('visitConfirmation'),
            },
            {
                ...startingConditionOptions.studyCreation,
                hidden: startingConditions.length > 1 || (isRemoteActivity && remoteType === 'recurring'),
            },
            {
                ...startingConditionOptions.afterActivityCompletion,
                hidden: isHiddenByCondition('afterActivityCompletion'),
            },
            {
                ...startingConditionOptions.activityResponse,
                hidden: isHiddenByCondition('activityResponse'),
            },
            {
                ...startingConditionOptions.dateResponse,
                hidden: activity?.id === DefaultActivityId.AUTHENTICATION || isHiddenByCondition('dateResponse'),
            },
        ];
    };
    const getValidEndingConditions = (condition) => {
        const isSiteOnStudyCreation = activity?.responderRole === ResponderRole.SITE &&
            startingConditions.some((condition) => condition.type === StartingConditionType.STUDY_CREATION);
        // We enable the end of day and after duration options only if a start condition isnt a patient flag
        const enableEodAndDuration = remoteType === 'recurring' ||
            (startingConditions.length === 1 &&
                (startingConditions[0].type === StartingConditionType.VISIT_CONFIRMATION ||
                    startingConditions[0].type === StartingConditionType.DATE_RESPONSE));
        const disableRemoteEod = isRemoteActivity &&
            startingConditions.some((condition) => condition.type === StartingConditionType.AFTER_ACTIVITY_COMPLETION ||
                condition.type === StartingConditionType.ACTIVITY_RESPONSE);
        const isRecurring = isRemoteActivity && remoteType === 'recurring';
        const isAdHoc = isRemoteActivity && remoteType === 'adhoc';
        const isOnce = isRemoteActivity && remoteType === 'once';
        const isHiddenByCondition = (key) => filteredEndingConditions.length > 1 && DISABLED_ENDING_MAP[condition.option.value][key];
        return [
            {
                ...endingConditionOptions.visitConfirmation,
                hidden: !isRemoteActivity || isRecurring || isHiddenByCondition('visitConfirmation'),
            },
            {
                ...endingConditionOptions.endless,
                hidden: !isRemoteActivity || isRecurring || isOnce || filteredEndingConditions.length > 1,
            },
            {
                ...endingConditionOptions.endOfDay,
                hidden: isAdHoc ||
                    isSiteOnStudyCreation ||
                    !enableEodAndDuration ||
                    disableRemoteEod ||
                    isHiddenByCondition('endOfDay'),
            },
            {
                ...endingConditionOptions.uponCompletion,
                hidden: isRecurring || isAdHoc || filteredEndingConditions.length > 1,
            },
            {
                ...endingConditionOptions.afterDuration,
                hidden: isAdHoc || isSiteOnStudyCreation || !enableEodAndDuration || isHiddenByCondition('afterDuration'),
            },
            {
                ...endingConditionOptions.afterActivityCompletion,
                hidden: isRecurring || isHiddenByCondition('afterActivityCompletion'),
            },
            {
                ...endingConditionOptions.activityResponse,
                hidden: isRecurring || isHiddenByCondition('activityResponse'),
            },
            {
                ...endingConditionOptions.dateResponse,
                hidden: isRecurring || isHiddenByCondition('dateResponse'),
            },
        ];
    };
    const getValidRecurringConditions = (condition) => {
        const isHiddenByCondition = (key) => repetitionEndConditions.length > 1 && DISABLED_RECURRENCE_MAP[condition.option.value][key];
        return [
            {
                ...endingConditionOptions.endless,
                hidden: isHiddenByCondition('endless'),
            },
            {
                ...endingConditionOptions.numOcurrences,
                hidden: isHiddenByCondition('numOcurrences'),
            },
            {
                ...endingConditionOptions.afterActivityCompletion,
                hidden: isHiddenByCondition('afterActivityCompletion'),
            },
            {
                ...endingConditionOptions.visitConfirmation,
                hidden: isHiddenByCondition('visitConfirmation'),
            },
            {
                ...endingConditionOptions.activityResponse,
                hidden: isHiddenByCondition('activityResponse'),
            },
        ];
    };
    const addStartCondition = () => {
        onPristineChange && onPristineChange(false);
        const newOption = startingConditions[0].option.value === StartingConditionType.VISIT_CONFIRMATION ||
            startingConditions[0].option.value === StartingConditionType.DATE_RESPONSE ||
            (!visitConfirmationOption &&
                startingConditions[0].option.value !== StartingConditionType.AFTER_ACTIVITY_COMPLETION &&
                startingConditions[0].option.value !== StartingConditionType.STUDY_CREATION)
            ? {
                id: v4(),
                option: startingConditionOptions.afterActivityCompletion,
                activityCompletion: activityCompletionOptions[0],
                offset: dayOptions[0],
                type: StartingConditionType.AFTER_ACTIVITY_COMPLETION,
            }
            : visitConfirmationOption
                ? {
                    id: v4(),
                    option: startingConditionOptions.visitConfirmation,
                    activityCompletion: visitConfirmationOption,
                    offset: dayOptions[0],
                    type: StartingConditionType.VISIT_CONFIRMATION,
                }
                : startingConditions[0].option.value !== StartingConditionType.AFTER_ACTIVITY_COMPLETION &&
                    startingConditions[0].option.value !== StartingConditionType.STUDY_CREATION
                    ? {
                        id: v4(),
                        option: startingConditionOptions.afterActivityCompletion,
                        activityCompletion: activityCompletionOptions[0],
                        offset: dayOptions[0],
                        type: StartingConditionType.AFTER_ACTIVITY_COMPLETION,
                    }
                    : {
                        id: v4(),
                        option: startingConditionOptions.activityResponse,
                        activityResponse: getDefaultActivityResponse(vrsResponseOptions),
                        type: StartingConditionType.ACTIVITY_RESPONSE,
                    };
        setStartingConditions((conditions) => [...conditions, newOption]);
        // We remove the end of day and after duration conditions if they exist as they are not allowed with multiple start conditions
        if (remoteType !== 'recurring') {
            replaceEodAndAfterDurationConditions();
        }
        else {
            if (endingConditions[0].type === EndingConditionType.END_OF_DAY) {
                setEndingConditions([
                    {
                        id: v4(),
                        option: endingConditionOptions.afterDuration,
                        type: EndingConditionType.AFTER_DURATION,
                        duration: '1',
                    },
                ]);
            }
        }
    };
    const repetitionEndConditions = endingConditions.filter((condition) => filterArr.includes(condition.type));
    const addEndCondition = () => {
        onPristineChange && onPristineChange(false);
        let newOption;
        if (remoteType === 'recurring') {
            newOption =
                repetitionEndConditions[0].option.value === EndingConditionType.VISIT_CONFIRMATION
                    ? {
                        id: v4(),
                        option: endingConditionOptions.afterActivityCompletion,
                        activityCompletion: activityCompletionOptions[0],
                        offset: dayOptions[0],
                        type: EndingConditionType.AFTER_ACTIVITY_COMPLETION,
                    }
                    : {
                        id: v4(),
                        option: endingConditionOptions.visitConfirmation,
                        type: EndingConditionType.VISIT_CONFIRMATION,
                        activityCompletion: visitConfirmationOption,
                        offset: dayOptions[0],
                    };
        }
        else {
            newOption =
                endingConditions[0].option.value === EndingConditionType.UPON_COMPLETION ||
                    endingConditions[0].option.value === EndingConditionType.END_OF_DAY ||
                    endingConditions[0].option.value === EndingConditionType.AFTER_DURATION ||
                    endingConditions[0].option.value === EndingConditionType.DATE_RESPONSE ||
                    (isRemoteActivity && endingConditions[0].option.value === EndingConditionType.VISIT_CONFIRMATION)
                    ? {
                        id: v4(),
                        option: endingConditionOptions.afterActivityCompletion,
                        activityCompletion: activityCompletionOptions[0],
                        offset: dayOptions[0],
                        type: EndingConditionType.AFTER_ACTIVITY_COMPLETION,
                    }
                    : isRemoteActivity
                        ? {
                            id: v4(),
                            option: endingConditionOptions.visitConfirmation,
                            type: EndingConditionType.VISIT_CONFIRMATION,
                            activityCompletion: visitConfirmationOption,
                            offset: dayOptions[0],
                        }
                        : endingConditions[0].option.value === EndingConditionType.ACTIVITY_RESPONSE
                            ? {
                                id: v4(),
                                option: endingConditionOptions.dateResponse,
                                type: EndingConditionType.DATE_RESPONSE,
                                offset: dayOptions[0],
                                activityResponse: getDefaultActivityResponse(dateTimeResponseOptions),
                            }
                            : {
                                id: v4(),
                                option: endingConditionOptions.endOfDay,
                                type: EndingConditionType.END_OF_DAY,
                            };
        }
        setEndingConditions([...endingConditions, newOption]);
    };
    const deleteCondition = (index, type) => {
        onPristineChange && onPristineChange(false);
        if (type === 'start') {
            setStartingConditions((conditions) => conditions.filter((_, i) => i !== index));
            return;
        }
        if (endingConditions[index].type === EndingConditionType.NUM_OF_OCURRENCES) {
            setRepetition((oldForm) => ({
                ...oldForm,
                count: undefined,
            }));
        }
        setEndingConditions((conditions) => conditions.filter((_, i) => i !== index));
    };
    const handleConditionInputChange = (conditionIndex, event) => {
        const { id, value } = event.target;
        if (GenericUtils.isPositiveInputNumberValid(value, MAX_DURATION)) {
            handleEndingSelectChange(conditionIndex, id, value);
        }
    };
    /**
     * Handles the blur event for numeric inputs
     * when input is empty, set the value to 1
     */
    const handleConditionInputBlur = (conditionIndex, event) => {
        const { id, value } = event.target;
        if (!value) {
            handleEndingSelectChange(conditionIndex, id, '1');
        }
    };
    const handleRepetitionInputChange = (event) => {
        const { id, value } = event.target;
        if (GenericUtils.isPositiveInputNumberValid(value, MAX_REPETITION_EVERY)) {
            onPristineChange(false);
            setRepetition((oldRepetition) => ({ ...oldRepetition, [id]: value }));
        }
    };
    /**
     * Handles the blur event for numeric inputs
     * when input is empty, set the value to 1
     */
    const handleRepetitionInputBlur = (event) => {
        const { id, value } = event.target;
        if (!value) {
            setRepetition((oldRepetition) => ({ ...oldRepetition, [id]: '1' }));
        }
    };
    const handleRepetitionChange = (option) => {
        onPristineChange(false);
        setRepetition((oldForm) => ({ ...oldForm, repetitionEveryPeriod: option }));
    };
    const handleRecurrenceEndingOptionChange = (option, conditionIdx) => {
        if (endingConditions[conditionIdx]?.option.value === option.value) {
            return;
        }
        onPristineChange(false);
        const newConditions = [...endingConditions];
        const newCondition = option.value === EndingConditionType.VISIT_CONFIRMATION
            ? {
                id: v4(),
                option: endingConditionOptions.visitConfirmation,
                activityCompletion: visitConfirmationOption,
                offset: newConditions[conditionIdx]?.offset || dayOptions[0],
                type: EndingConditionType.VISIT_CONFIRMATION,
            }
            : option.value === EndingConditionType.ACTIVITY_RESPONSE
                ? {
                    id: v4(),
                    option: endingConditionOptions.activityResponse,
                    activityResponse: getDefaultActivityResponse(vrsResponseOptions),
                    type: EndingConditionType.ACTIVITY_RESPONSE,
                }
                : option.value === EndingConditionType.AFTER_ACTIVITY_COMPLETION
                    ? {
                        id: v4(),
                        option: endingConditionOptions.afterActivityCompletion,
                        activityCompletion: activityCompletionOptions[0],
                        offset: newConditions[conditionIdx]?.offset || dayOptions[0],
                        type: EndingConditionType.AFTER_ACTIVITY_COMPLETION,
                    }
                    : {
                        id: v4(),
                        type: option.value,
                        option: endingConditionOptions[option.value],
                    };
        newConditions[conditionIdx] = newCondition;
        if (newConditions.every((condition) => condition.type !== EndingConditionType.NUM_OF_OCURRENCES)) {
            setRepetition((oldForm) => ({
                ...oldForm,
                count: undefined,
            }));
        }
        else if (newCondition.type === EndingConditionType.NUM_OF_OCURRENCES) {
            setRepetition((oldForm) => ({
                ...oldForm,
                count: '1',
            }));
        }
        setEndingConditions(newConditions);
    };
    const onSpecificStartTimeChange = (value) => {
        onPristineChange(false);
        setSpecificStartTime(value);
    };
    // For Site activities, we only duration to be set in days
    const durationPeriodOptions = activity?.responderRole === ResponderRole.SITE
        ? periodOptions.map((option) => ({ ...option, isDisabled: option.value !== 'days' }))
        : periodOptions;
    const isSequential = activity?.responderRole === ResponderRole.PARTICIPANT && visit?.sequentialActivityOrder;
    // UI hack for display sequential visit activity start condition
    let prevSequentialActivityCondition;
    if (isSequential && visit && activity) {
        const orderedActivities = Object.values(visit.activities)
            .sort((activityA, activityB) => (activityA.order || 0) - (activityB.order || 0))
            .filter((activity) => activity.responderRole === ResponderRole.PARTICIPANT);
        const activityIdx = orderedActivities.findIndex((orderedActivity) => orderedActivity.id === activity.id);
        const prevSequentialActivity = orderedActivities[activityIdx - 1];
        if (prevSequentialActivity && prevSequentialActivity.id !== DefaultActivityId.VISIT_CONFIRMATION) {
            const prevInstance = visit.getActivitySequenceByActivityId(prevSequentialActivity.id);
            // This will only be used for display purposes. The underlying start condition of "Visit Confirmation"/"Study creation"
            // will still be saved to rosetta
            prevSequentialActivityCondition = {
                id: v4(),
                option: startingConditionOptions.afterActivityCompletion,
                activityCompletion: activityCompletionOptions.find((option) => option.value === prevInstance?.instanceId),
                offset: dayOptions[0],
                type: StartingConditionType.AFTER_ACTIVITY_COMPLETION,
            };
        }
    }
    const shouldShowAddEndConditionButton = () => {
        if (remoteType === 'recurring' || filteredEndingConditions.length > 1) {
            return false;
        }
        // if the only condition is after activity completion, and the start condition is visit confirmation or date response, we allow adding another end condition
        // any other starting condition will disable the end of day and duration options and so we don't allow adding another end condition
        if (filteredEndingConditions[0].type === EndingConditionType.AFTER_ACTIVITY_COMPLETION) {
            return (startingConditions.length === 1 &&
                (startingConditions[0].type === StartingConditionType.VISIT_CONFIRMATION ||
                    startingConditions[0].type === StartingConditionType.DATE_RESPONSE));
        }
        return (filteredEndingConditions[0].type !== EndingConditionType.ENDLESS &&
            filteredEndingConditions[0].type !== EndingConditionType.UPON_COMPLETION);
    };
    const sections = [
        {
            title: translate('Availability start'),
            description: translate('Choose when this activity is available for the first time.'),
            content: (_jsxs("section", { className: 'flex flex-col gap-6 max-w-input-lg', "data-testid": 'activity-instance-start-availability', children: [startingConditions.map((startCondition, index) => {
                        const condition = isSequential && prevSequentialActivityCondition ? prevSequentialActivityCondition : startCondition;
                        const isStartOffsetDisabled = condition.option.value !== StartingConditionType.VISIT_CONFIRMATION &&
                            condition.option.value !== StartingConditionType.DATE_RESPONSE;
                        const isChooseActivity = condition.type === StartingConditionType.AFTER_ACTIVITY_COMPLETION;
                        return (_jsxs("section", { className: 'flex flex-col gap-4', children: [_jsxs("section", { className: 'flex gap-4 items-end relative', children: [_jsx(Select, { dataTestId: `activity-instance-start-activity-type-${index}`, onChange: (option) => handleStartingConditionChange(index, option), options: getValidStartingConditions(index === 0 ? startingConditions[1] : startingConditions[0]), value: condition.option, label: translate('Starts after'), width: 'full', disabled: isSequential }), !isStartOffsetDisabled && index === 0 && (_jsx(Select, { dataTestId: `activity-instance-start-offset-${index}`, onChange: (option) => handleStartingSelectChange(index, 'offset', option), label: translate('Offset (days)'), options: dayOptions, value: condition.offset || dayOptions[0], width: 'sm', disabled: isSequential })), startingConditions.length > 1 && (_jsx("div", { className: 'absolute -right-16', children: _jsx(Button, { label: translate('Delete'), content: _jsx(Icon, { name: 'delete' }), type: 'button', variant: 'transparent', onClick: () => deleteCondition(index, 'start') }) }))] }), ((isRemoteActivity && condition.option.value !== StartingConditionType.STUDY_CREATION) ||
                                    (!isRemoteActivity &&
                                        condition.option.value !== StartingConditionType.VISIT_CONFIRMATION &&
                                        condition.option.value !== StartingConditionType.STUDY_CREATION)) && (_jsx("div", { className: 'h-px bg-neutral-300' })), isRemoteActivity && condition.option.value === StartingConditionType.VISIT_CONFIRMATION && (_jsxs("section", { className: 'flex items-center gap-4', children: [_jsx(Text, { size: 'sm', className: 'font-medium w-input-xs', children: translate('At visit') }), _jsx(Select, { dataTestId: `activity-instance-start-confirmation-visit-${index}`, onChange: (option) => handleStartingSelectChange(index, 'visitId', option), options: allVisitOptions, value: condition.visitId || allVisitOptions[0], width: 'full' })] })), isChooseActivity && (_jsxs("section", { className: 'flex items-center gap-4', children: [_jsx(Text, { size: 'sm', className: 'font-medium w-input-xs', children: translate('Activity') }), _jsx(Select, { dataTestId: `activity-instance-start-activity-completion-${index}`, onChange: (option) => handleStartingSelectChange(index, 'activityCompletion', option), options: activityCompletionOptions, value: condition.activityCompletion || activityCompletionOptions[0], width: 'full', disabled: isSequential })] })), (condition.type === StartingConditionType.ACTIVITY_RESPONSE ||
                                    condition.type === StartingConditionType.DATE_RESPONSE) && (_jsx(SelectActivityResponse, { screenType: condition.type === StartingConditionType.DATE_RESPONSE ? 'date_time' : 'vrs', options: condition.type === StartingConditionType.DATE_RESPONSE
                                        ? dateTimeResponseOptions
                                        : vrsResponseOptions, onSelectedOptionChange: (option) => handleStartingSelectChange(index, 'activityResponse', option), selectedOption: condition.activityResponse })), startingConditions.length !== index + 1 && (_jsxs("div", { className: 'flex items-center', children: [_jsx(Text, { size: 'sm', className: 'mx-4 text-secondary font-medium', children: translate('And') }), _jsx("div", { className: 'w-full h-px bg-neutral-300' })] }))] }, `${condition.option.value}-${index}`));
                    }), !isSequential &&
                        startingConditions.length === 1 &&
                        startingConditions[0].type !== StartingConditionType.STUDY_CREATION &&
                        activity?.id !== DefaultActivityId.AUTHENTICATION && (_jsx("section", { children: _jsx(Button, { type: 'button', variant: 'transparent', label: translate('+ Add condition'), onClick: addStartCondition }) })), isSequential && (_jsx("section", { children: _jsx(InlineNotification, { title: translate('Sequential order activated'), subtitle: translate('Sequential order is turned on for activities in this visit. To change start conditions please change the activity order setting for this visit first.') }) })), remoteType === 'recurring' && (_jsxs("section", { children: [_jsx(Checkbox, { label: translate('Start at specific time of day'), description: translate('Choose what time each instance after the first instance will start. If not selected, instances will start at midnight.'), checked: !!showSpecificStartTime, onChange: onShowSpecificStartTimeChange }), _jsx("div", { className: 'ml-8', children: _jsx(TimePicker, { value: specificStartTime, onChange: onSpecificStartTimeChange, disabled: !showSpecificStartTime }) })] }))] })),
        },
        {
            title: translate('Availability end'),
            description: isRemoteActivity
                ? translate('Customize when this activity is no longer available.')
                : translate('Customize when this activity is no longer available. By default, the activity is made unavailable at the start of the next visit, or if the participant is deactivated.'),
            hidden: activity?.id === DefaultActivityId.AUTHENTICATION,
            content: (_jsxs("section", { className: 'flex flex-col gap-6 max-w-input-lg', "data-testid": 'activity-instance-end-availability', children: [filteredEndingConditions.map((condition, index) => (_jsxs("section", { className: 'flex flex-col gap-4', children: [_jsxs("section", { className: 'flex gap-4 items-end relative', children: [_jsx(Select, { dataTestId: `activity-instance-end-trigger-${index}`, label: translate('Ends'), onChange: (option) => handleEndingConditionChange(index, option), options: getValidEndingConditions(index === 0 ? filteredEndingConditions[1] : filteredEndingConditions[0]), value: condition.option, width: 'full' }), index === 0 &&
                                        (condition.option.value === EndingConditionType.DATE_RESPONSE ||
                                            condition.option.value === EndingConditionType.VISIT_CONFIRMATION ||
                                            condition.option.value === EndingConditionType.NUM_OF_OCURRENCES) && (_jsx(Select, { dataTestId: `activity-instance-offset-${index}`, onChange: (option) => handleEndingSelectChange(index, 'offset', option), label: translate('Offset (days)'), options: dayOptions, value: condition.offset || dayOptions[0], width: 'sm', disabled: isSequential })), remoteType !== 'recurring' && filteredEndingConditions.length > 1 && (_jsx("div", { className: 'absolute -right-16', children: _jsx(Button, { label: translate('Delete'), content: _jsx(Icon, { name: 'delete' }), type: 'button', variant: 'transparent', onClick: () => deleteCondition(index, 'end') }) }))] }), condition.option.value !== EndingConditionType.END_OF_DAY &&
                                condition.option.value !== EndingConditionType.UPON_COMPLETION &&
                                condition.option.value !== EndingConditionType.ENDLESS && _jsx("div", { className: 'h-px bg-neutral-300' }), isRemoteActivity && condition.option.value === EndingConditionType.VISIT_CONFIRMATION && (_jsxs("section", { className: 'flex items-center gap-4', children: [_jsx(Text, { size: 'sm', className: 'font-medium w-input-xs', children: translate('At visit') }), _jsx(Select, { dataTestId: `activity-instance-end-confirmation-visit-${index}`, onChange: (option) => handleEndingSelectChange(index, 'visitId', option), options: allVisitOptions, value: condition.visitId || allVisitOptions[0], width: 'full' })] })), condition.option.value === EndingConditionType.AFTER_DURATION && (_jsxs("section", { className: 'flex items-center gap-4', children: [_jsx(Input, { dataTestId: `activity-instance-duration-${index}`, id: 'duration', max: MAX_DURATION, min: '1', onBlur: (event) => handleConditionInputBlur(index, event), onChange: (event) => handleConditionInputChange(index, event), type: 'number', value: condition.duration, width: 'sm', containLabels: true }), _jsx(Select, { dataTestId: `activity-instance-duration-period-${index}`, onChange: (option) => handleEndingSelectChange(index, 'durationPeriod', option), options: durationPeriodOptions, value: condition.durationPeriod || periodOptions[1], width: 'full' })] })), condition.option.value === EndingConditionType.AFTER_ACTIVITY_COMPLETION && (_jsxs("section", { className: 'flex items-center gap-4', children: [_jsx(Text, { size: 'sm', className: 'font-medium w-input-xs', children: translate('Activity') }), _jsx(Select, { dataTestId: `activity-instance-end-activity-completion-${index}`, onChange: (option) => handleEndingSelectChange(index, 'activityCompletion', option), options: activityCompletionOptions, value: condition.activityCompletion || activityCompletionOptions[1], width: 'full' })] })), (condition.option.value === EndingConditionType.ACTIVITY_RESPONSE ||
                                condition.option.value === EndingConditionType.DATE_RESPONSE) && (_jsx(SelectActivityResponse, { screenType: condition.type === EndingConditionType.DATE_RESPONSE ? 'date_time' : 'vrs', options: condition.type === EndingConditionType.DATE_RESPONSE ? dateTimeResponseOptions : vrsResponseOptions, onSelectedOptionChange: (option) => handleEndingSelectChange(index, 'activityResponse', option), selectedOption: condition.activityResponse })), filteredEndingConditions.length !== index + 1 && (_jsxs("div", { className: 'flex items-center', children: [_jsx(Text, { size: 'sm', className: 'mx-4 text-secondary font-medium', children: translate('Or') }), _jsx("div", { className: 'w-full h-px bg-neutral-300' })] }))] }, condition.option.value))), shouldShowAddEndConditionButton() && (_jsx("section", { children: _jsx(Button, { type: 'button', variant: 'transparent', label: translate('+ Add condition'), onClick: () => addEndCondition() }) }))] })),
        },
        {
            title: translate('Repetition'),
            hidden: !isRemoteActivity || remoteType !== 'recurring',
            description: translate('Configure repetition settings. Days before offset will not apply for the first instance of the activity.'),
            content: (_jsxs("section", { className: 'flex flex-col gap-4 max-w-input-lg', "data-testid": 'activity-instance-repetition', children: [_jsxs("section", { className: 'flex items-end gap-4', children: [_jsx(Input, { dataTestId: 'activity-instance-repetition-every-input', label: translate('Repeats every'), id: 'repetitionEvery', max: MAX_REPETITION_EVERY, min: '1', onChange: handleRepetitionInputChange, onBlur: handleRepetitionInputBlur, type: 'number', value: repetition.repetitionEvery, width: 'sm', containLabels: true }), _jsx(Select, { dataTestId: 'activity-instance-repetition-every-period', onChange: handleRepetitionChange, options: repetitionOptions, value: repetition.repetitionEveryPeriod, width: 'full' })] }), _jsxs("section", { className: 'flex flex-col gap-6', children: [repetitionEndConditions.map((condition, index) => (_jsxs("section", { className: 'flex flex-col gap-4', children: [_jsxs("section", { className: 'flex items-end gap-4 relative', children: [_jsx(Select, { label: translate('Until'), dataTestId: `activity-instance-repetition-until-condition-${index}`, onChange: (option) => handleRecurrenceEndingOptionChange(option, index + 1), options: getValidRecurringConditions(index === 0 ? repetitionEndConditions[1] : repetitionEndConditions[0]), value: condition.option, width: 'full' }), condition.type !== EndingConditionType.NUM_OF_OCURRENCES &&
                                                condition.type !== EndingConditionType.ACTIVITY_RESPONSE &&
                                                condition.type !== EndingConditionType.ENDLESS &&
                                                condition.type !== EndingConditionType.AFTER_ACTIVITY_COMPLETION && (_jsx(Select, { dataTestId: `activity-instance-repetition-offset-${index}`, onChange: (option) => handleEndingSelectChange(index + 1, 'offset', option), label: translate('Offset (days)'), options: dayOptions, value: endingConditions.find((c) => c.type === condition.type)?.offset || dayOptions[0], width: 'sm' })), repetitionEndConditions.length > 1 && (_jsx("div", { className: 'absolute -right-16', children: _jsx(Button, { label: translate('Delete'), content: _jsx(Icon, { name: 'delete' }), type: 'button', variant: 'transparent', onClick: () => deleteCondition(index + 1, 'end') }) }))] }), condition.type !== EndingConditionType.ENDLESS && _jsx("div", { className: 'h-px bg-neutral-300' }), condition.type === EndingConditionType.NUM_OF_OCURRENCES && (_jsxs("section", { className: 'flex items-center gap-4', children: [_jsx(Text, { size: 'sm', className: 'font-medium w-input-xs', children: translate('Occurrences') }), _jsx(Input, { dataTestId: `activity-instance-repetition-count-input-${index}`, id: 'count', min: '1', onChange: handleRepetitionInputChange, onBlur: handleRepetitionInputBlur, type: 'number', value: repetition.count, width: 'full', containLabels: true })] })), condition.type === EndingConditionType.VISIT_CONFIRMATION && (_jsxs("section", { className: 'flex items-center gap-4', children: [_jsx(Text, { size: 'sm', className: 'font-medium w-input-xs', children: translate('At visit') }), _jsx(Select, { dataTestId: `activity-instance-repetition-end-confirmation-visit-${index}`, onChange: (option) => handleEndingSelectChange(index + 1, 'visitId', option), options: allVisitOptions, value: endingConditions.find((c) => c.type === EndingConditionType.VISIT_CONFIRMATION)?.visitId ||
                                                    allVisitOptions[0], width: 'full' })] })), condition.type === EndingConditionType.ACTIVITY_RESPONSE && (_jsx(SelectActivityResponse, { screenType: 'vrs', options: vrsResponseOptions, onSelectedOptionChange: (option) => handleEndingSelectChange(index + 1, 'activityResponse', option), selectedOption: endingConditions.find((c) => c.type === EndingConditionType.ACTIVITY_RESPONSE)
                                            ?.activityResponse || getDefaultActivityResponse(vrsResponseOptions) })), condition.type === EndingConditionType.AFTER_ACTIVITY_COMPLETION && (_jsxs("section", { className: 'flex items-center gap-4', children: [_jsx(Text, { size: 'sm', className: 'font-medium w-input-xs', children: translate('Activity') }), _jsx(Select, { dataTestId: `activity-instancerepetition-end-activity-completion-${index}`, onChange: (option) => handleEndingSelectChange(index + 1, 'activityCompletion', option), options: activityCompletionOptions, value: endingConditions.find((c) => c.type === EndingConditionType.AFTER_ACTIVITY_COMPLETION)
                                                    ?.activityCompletion || activityCompletionOptions[1], width: 'full' })] })), repetitionEndConditions.length !== index + 1 && (_jsxs("div", { className: 'flex items-center', children: [_jsx(Text, { size: 'sm', className: 'mx-4 text-secondary font-medium', children: translate('Or') }), _jsx("div", { className: 'w-full h-px bg-neutral-300' })] }))] }, condition.type))), repetitionEndConditions.length === 0 && (_jsx("section", { className: 'flex items-end gap-4', children: _jsx(Select, { label: translate('Until'), dataTestId: 'activity-instance-repetition-until-condition-0', onChange: (option) => handleRecurrenceEndingOptionChange(option, 1), options: recurrenceEndingOptions, value: recurrenceEndingOptions[0], width: 'full' }) })), remoteType === 'recurring' &&
                                repetitionEndConditions.length === 1 &&
                                repetitionEndConditions[0].type !== EndingConditionType.ENDLESS && (_jsx("section", { children: _jsx(Button, { type: 'button', variant: 'transparent', label: translate('+ Add condition'), onClick: () => addEndCondition() }) }))] })] })),
        },
    ];
    return _jsx(FormSections, { sections: sections });
};
