import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useMemo, useState } from 'react';
import JsonView from 'react18-json-view';
import 'react18-json-view/src/style.css';
import { LivePreview } from 'libs.ecoa_live_preview';
import { useLocalizeMessage, ModalWindow, Text } from 'libs.nucleus';
import { generateUserInputFromIframeEvents } from '../../../utils';
const TestPlanUserInputEditModal = ({ onClose: closeModal, exitEditMode, ecoaActivity, activity, cell, onChange, study, }) => {
    const translate = useLocalizeMessage();
    const [showIframe, setShowIframe] = useState(false);
    const [value, setValue] = useState();
    const [isValueDirty, setIsValueDirty] = useState(false);
    const setConfigForTask = () => {
        let config;
        if (activity.isVisitConfirmation) {
            config = '{"screens": [{"type": "radio","value": 0}]}';
        }
        else if (activity.name === 'Enter Participant Number') {
            const participantNumberFormat = study.subjectIdFormat;
            const hashes = participantNumberFormat.replace(/[^#]/g, '');
            const format = hashes ? hashes.length : 5;
            config = `{"screens":[{"type":"form","fields":[{"type":"text","value":"participant_number","generator":{"type":"random-number","format":"${'#'.repeat(format)}"}}]}]}`;
        }
        else if (activity.name === 'Authentication') {
            config = '{}';
        }
        if (config) {
            setValue(JSON.parse(config));
        }
    };
    useEffect(() => {
        if (cell?.value) {
            try {
                setValue(JSON.parse(cell.value));
            }
            catch (e) {
                console.error('Error parsing JSON', e);
            }
        }
        else if (!ecoaActivity) {
            setConfigForTask();
        }
    }, [cell?.value, ecoaActivity]);
    useEffect(() => {
        if (cell?.value && JSON.stringify(value) === cell.value) {
            setIsValueDirty(false);
        }
        else {
            setIsValueDirty(true);
        }
    }, [cell?.value, value]);
    const onClose = () => {
        if (showIframe) {
            setShowIframe(false);
            return;
        }
        closeModal();
        exitEditMode();
    };
    const onSave = () => {
        onChange({
            ...cell,
            value: JSON.stringify(value),
        });
        onClose();
    };
    const savePrimaryButton = showIframe
        ? undefined
        : {
            label: translate('Save'),
            onClick: onSave,
            disabled: !isValueDirty,
        };
    const cancelSecondaryButton = {
        label: translate('Cancel'),
        onClick: onClose,
    };
    const editButton = !showIframe && ecoaActivity
        ? {
            label: translate('Edit'),
            onClick: () => setShowIframe(true),
        }
        : undefined;
    const answers = useMemo(() => {
        if (value) {
            const { screens } = value;
            const answers = {};
            if (screens && screens.length > 0) {
                screens.forEach((screen) => {
                    if (screen.stepId) {
                        if (screen.type === 'form' && screen.fields && screen.fields.length > 0) {
                            answers[screen.stepId] = { value: screen.fields[0].value, text: screen.fields[0].value };
                        }
                        else {
                            answers[screen.stepId] = { value: screen.value, text: screen.value };
                        }
                    }
                });
            }
            return answers;
        }
        return undefined;
    }, [value]);
    global.submit = (iframeResponse) => {
        if (!ecoaActivity) {
            return;
        }
        const userInput = generateUserInputFromIframeEvents(iframeResponse, ecoaActivity);
        setValue(userInput);
        setShowIframe(false);
    };
    return (_jsx(ModalWindow, { title: translate('User Input'), headerActionButton: editButton, isOpen: true, closeWindow: onClose, footerPrimaryActionButton: savePrimaryButton, footerSecondaryActionButtons: [cancelSecondaryButton], footerCenterContent: true, width: 'full', children: showIframe && ecoaActivity ? (_jsx("div", { className: 'flex flex-col items-center', children: _jsx(LivePreview, { activity: ecoaActivity, showSizeSelector: false }) })) : value && ecoaActivity ? (_jsx("div", { className: 'flex flex-row flex-wrap gap-4', children: value.screens?.map((screen) => {
                if (screen.stepId) {
                    const screenIndex = ecoaActivity.screens.findIndex((s) => s.key === screen.stepId);
                    return (_jsx(LivePreview, { activity: ecoaActivity, answers: answers, showSizeSelector: false, disableInteractions: true, screenIndex: screenIndex, className: '!static !ml-0 !mr-0' }, screen.stepId));
                }
                return null;
            }) })) : value ? (_jsx("div", { children: _jsx(JsonView, { src: value, theme: 'github', collapsed: 5, displaySize: 1 }) })) : (_jsx(Text, { className: 'text-center', children: translate('No user input configuration found') })) }));
};
export default TestPlanUserInputEditModal;
