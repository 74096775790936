import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Duration } from 'luxon';
import { useState, useMemo, useContext } from 'react';
import { useLocalizeMessage, ModalWindow, Text } from 'libs.nucleus';
import { StudyConfigurationContext } from '../../../../contexts';
import { useActivityUtils } from '../../../../hooks';
import { ActivityDependencies, EndingConditionType, StartingConditionType, useActivityDependencyUtils, } from '../activity_dependencies';
export const RemoteActivityEditModal = ({ initialActivity, onCancel, onSave, activityName, activityType, }) => {
    const translate = useLocalizeMessage();
    const activityDependencyUtils = useActivityDependencyUtils({
        isRemoteActivity: true,
        currentActivity: initialActivity,
    });
    const [isPristine, setIsPristine] = useState(true);
    const { startingConditions, endingConditions, specificStartTime, repetition } = activityDependencyUtils;
    const [showSpecificStartTime, setShowSpecificStartTime] = useState(!!specificStartTime.time);
    const { getActivityIcon } = useActivityUtils();
    const { currentStudy, currentTimelineId } = useContext(StudyConfigurationContext);
    const handleShowSpecificStartTimeChange = (show) => {
        setIsPristine(false);
        setShowSpecificStartTime(show);
    };
    const isSaveDisabled = useMemo(() => {
        if (isPristine) {
            return true;
        }
        if (showSpecificStartTime && !specificStartTime?.time) {
            return true;
        }
        if (initialActivity.timing.occurrence === 'recurring' && !repetition.repetitionEvery) {
            return true;
        }
        return (startingConditions.some((condition) => (condition.type === StartingConditionType.ACTIVITY_RESPONSE &&
            Object.values(condition.activityResponse).some((v) => !v.value)) ||
            (condition.type === StartingConditionType.DATE_RESPONSE &&
                (condition.activityResponse.activity.value === '' || condition.activityResponse.screen.value === '')) ||
            (condition.type === StartingConditionType.VISIT_CONFIRMATION && !condition.visitId?.value)) ||
            endingConditions.some((condition) => (condition.type === EndingConditionType.AFTER_ACTIVITY_COMPLETION && !condition.activityCompletion.value) ||
                (condition.type === EndingConditionType.AFTER_DURATION && !condition.duration) ||
                (condition.type === EndingConditionType.ACTIVITY_RESPONSE &&
                    Object.values(condition.activityResponse).some((v) => !v.value)) ||
                (condition.type === EndingConditionType.DATE_RESPONSE &&
                    (condition.activityResponse.activity.value === '' || condition.activityResponse.screen.value === '')) ||
                (condition.type === EndingConditionType.VISIT_CONFIRMATION && !condition.visitId?.value)) ||
            (repetition.count !== undefined && Number(repetition.count) < 1));
    }, [startingConditions, endingConditions, isPristine, showSpecificStartTime, specificStartTime, repetition]);
    const handleSave = () => {
        const startTime = showSpecificStartTime && specificStartTime?.time
            ? Duration.fromObject({ hours: specificStartTime.time.hour, minute: specificStartTime.time.minute }).toISO()
            : undefined;
        onSave({
            startConditions: startingConditions,
            endConditions: endingConditions,
            selectedActivities: [],
            type: initialActivity.timing.occurrence,
            specificStartTime: startTime,
            repetition,
        });
    };
    const onSubmit = (event) => {
        event.preventDefault();
        if (!isSaveDisabled) {
            handleSave();
        }
    };
    const handleEnterPress = (event) => {
        if (event.key === 'Enter' && !isSaveDisabled) {
            handleSave();
        }
    };
    const cancelButton = { label: translate('Cancel'), onClick: onCancel };
    const saveButton = {
        label: translate('Save'),
        onClick: handleSave,
        disabled: isSaveDisabled,
    };
    return (_jsx(ModalWindow, { title: translate('Edit non-visit activity'), subtitle: _jsxs("div", { className: 'flex items-center gap-2', children: [getActivityIcon(activityType), _jsx(Text, { size: 'sm', className: 'text-secondary', as: 'span', children: translate('{timeline} - {activity}', {
                        activity: activityName,
                        timeline: currentStudy.timelineInfo[currentTimelineId].name,
                    }) })] }), isOpen: true, width: 'full', footerPrimaryActionButton: saveButton, footerSecondaryActionButtons: [cancelButton], closeWindow: onCancel, children: _jsx("form", { className: 'flex min-h-[16rem] flex-col gap-4', autoComplete: 'off', onSubmit: onSubmit, onKeyUp: handleEnterPress, children: _jsx(ActivityDependencies, { isRemoteActivity: true, remoteType: initialActivity.timing.occurrence, activityDependencyUtils: activityDependencyUtils, onPristineChange: setIsPristine, showSpecificStartTime: showSpecificStartTime, onShowSpecificStartTimeChange: handleShowSpecificStartTimeChange }) }) }));
};
