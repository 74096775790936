import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useMatch } from 'react-router-dom';
import { useLocalizeMessage, Spinner, Tabs } from 'libs.nucleus';
import { NotFoundPage, PageHeader, PageWrapper, useBooleanState } from 'libs.react';
import { WorkspaceSiteDetails } from '../../components/workspace_site_details';
import { WorkspaceSiteModal } from '../../components/workspace_site_modal';
import { useFetchWorkspaceSiteById } from '../../hooks/useWorkspaceSites.hook';
export const WorkspaceSite = () => {
    const translate = useLocalizeMessage();
    const match = useMatch('/site-management/workspace-site/:workspaceSiteId');
    const [isEditModalOpen, setEditModalOpen, setEditModalClose] = useBooleanState(false);
    const workspaceSiteId = match?.params.workspaceSiteId;
    const { workspaceSite, isLoading } = useFetchWorkspaceSiteById(workspaceSiteId);
    const breadcrumbsPages = [
        { name: translate('Sites'), path: '/site-management/dashboard' },
        { name: workspaceSite?.name || '', path: '' },
    ];
    const headerOptions = [
        {
            name: translate('Edit'),
            onClick: setEditModalOpen,
            variant: 'primary',
            disabled: !workspaceSite,
            isLoading,
        },
    ];
    if (isLoading) {
        return _jsx(Spinner, { wrapper: 'full' });
    }
    if (!workspaceSite) {
        return _jsx(NotFoundPage, {});
    }
    return (_jsxs(PageWrapper, { children: [_jsx(PageHeader, { title: workspaceSite.name, options: headerOptions, breadcrumbPages: breadcrumbsPages }), _jsx(Tabs, { tabLabels: [translate('Details'), translate('Studies')], tabContent: [_jsx(WorkspaceSiteDetails, { workspaceSite: workspaceSite }, 'workspace_site_details'), _jsx(_Fragment, { children: "Studies" })], defaultIndex: 0, onTabChange: () => { } }), _jsx(WorkspaceSiteModal, { isOpen: isEditModalOpen, onClose: setEditModalClose, workspaceSite: workspaceSite })] }));
};
