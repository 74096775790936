import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { H3, H5, useLocalizeMessage } from 'libs.nucleus';
import { ListInformation, useCountriesOptions } from 'libs.react';
import { getCountryNameFromCode } from '../../utils';
export const EnvironmentSiteDetails = ({ environmentSite, workspaceSite }) => {
    const translate = useLocalizeMessage();
    const countriesOptions = useCountriesOptions();
    const environmentSiteInformation = [
        {
            title: translate('Name'),
            description: environmentSite.metadata.name,
        },
        {
            title: translate('Site library ID'),
            description: environmentSite.id,
        },
        {
            title: translate('Therapeutic area'),
            description: environmentSite.metadata.therapeuticArea,
        },
        {
            title: translate('Therapeutic area'),
            description: environmentSite.metadata.principalInvestigator,
        },
        {
            title: translate('Timezone'),
            description: environmentSite.metadata.timezone,
        },
        {
            title: translate('Site locales'),
            description: environmentSite.metadata.siteLocales?.join('), '),
        },
        {
            title: translate('Participant locales'),
            description: environmentSite.metadata.participantLocales?.join('), '),
        },
    ];
    const environmentSiteContactInformation = [
        {
            title: translate('Email'),
            description: environmentSite.metadata.email,
        },
        {
            title: translate('Phone'),
            description: environmentSite.metadata.address.phone.map((phone) => `${phone.type}: ${phone.number}`).join('), '),
        },
        {
            title: translate('Country'),
            description: getCountryNameFromCode(environmentSite.metadata.address.countryCode, countriesOptions),
        },
        {
            title: translate('Address'),
            description: environmentSite.metadata.address.addressLine,
        },
        {
            title: translate('City / Town'),
            description: environmentSite.metadata.address.city,
        },
        {
            title: translate('State / Province / Region'),
            description: environmentSite.metadata.address.region,
        },
        {
            title: translate('Zip / Postal code'),
            description: environmentSite.metadata.address.postalCode,
        },
    ];
    const workspaceSiteInformation = [
        {
            title: translate('Name'),
            description: workspaceSite.name,
        },
        {
            title: translate('Site library ID'),
            description: workspaceSite.number,
        },
        {
            title: translate('Timezone'),
            description: workspaceSite.timezone,
        },
        {
            title: translate('Site locales'),
            description: workspaceSite.siteLocales?.join('), '),
        },
    ];
    const workspaceSiteContactInformation = [
        {
            title: translate('Email'),
            description: workspaceSite.email,
        },
        {
            title: translate('Phone'),
            description: workspaceSite.address.phone.map((phone) => `${phone.type}: ${phone.number}`).join('), '),
        },
        {
            title: translate('Country'),
            description: getCountryNameFromCode(workspaceSite.address.countryCode, countriesOptions),
        },
        {
            title: translate('Address'),
            description: workspaceSite.address.addressLine,
        },
        {
            title: translate('City / Town'),
            description: workspaceSite.address.city,
        },
        {
            title: translate('State / Province / Region'),
            description: workspaceSite.address.region,
        },
        {
            title: translate('Zip / Postal code'),
            description: workspaceSite.address.postalCode,
        },
    ];
    return (_jsxs("section", { className: 'flex flex-col flex-1', children: [_jsx(H3, { className: 'mb-6 mt-0', children: translate('Study site') }), _jsx(ListInformation, { items: environmentSiteInformation }), _jsx(H5, { className: 'mb-6 mt-6', children: translate('Contact information') }), _jsx(ListInformation, { items: environmentSiteContactInformation }), _jsx(H3, { className: 'mb-6 mt-6', children: translate('Workspace Site') }), _jsx(ListInformation, { items: workspaceSiteInformation }), _jsx(H5, { className: 'mb-6 mt-6', children: translate('Contact information') }), _jsx(ListInformation, { items: workspaceSiteContactInformation })] }));
};
